<template>
  <div class="modal-body">
    <div class="close" @click="closeModal">
      <icon-close :width="'10px'" :height="'10px'" :fill="'#0085FF'" />
    </div>
    <div class="title">
      <div>{{ title }}</div>
    </div>
    <form @submit.prevent="submit">
      <div class="search">
        <app-search-input v-model="searchText" :placeholder="translate('search')" />
      </div>
      <div class="list">
        <ul>
          <li
            @click="submit(item)"
            :key="i"
            v-for="(item, i) in existsList"
          >
              <img :src="item.iconUri" /> {{ item.name }} <icon-attention :width="'16px'" :height="'16px'" v-if="item.isSelected" v-tooltip.top-center="'Selected on ' + item.selectedParentName" />
          </li>
        </ul>
      </div>
    </form>
  </div>
</template>

<script>
import {reactive, toRefs, computed, onMounted, inject} from "vue";
import { useModalHelper } from "@/helpers/modalHelper";
import IconClose from "@/components/icons/IconClose.vue";
import AppSearchInput from "@/components/shared/SearchInput.vue";
import attention from "../icons/attention.vue";
import { useStore } from "vuex";
export default {
  components: {
    IconClose,
    AppSearchInput,
      IconAttention: attention,

  },
  inject: ["translate"],
  props: {
    title: {
      type: String,
      default: "Add new node",
    },
    existsList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const store = useStore(),
      state = reactive({
        searchText: "",
        items: computed(() =>
        {
          const arr = store.getters["settings/issueTypes"].filter((i) => !i.subtask)
          return [...new Set([...new Map(arr.map((item) => [item["name"], item])).values()])]
        }

        ),
        filterItems: computed(() =>
          state.items.filter(
            (i) => i.name.toLowerCase().indexOf(state.searchText.toLowerCase()) !== -1
          )
        ),
      });

    const { openModal, closeModal, submitModal, storeModal } = useModalHelper();
      const submit = (item) => {
          submitModal(item);
      };
    onMounted(() => {
      store.dispatch("settings/getIssueTypes");
    });
    return {
      ...toRefs(state),
      closeModal,
      submit,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  position: relative;
  .close {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -21px;
    top: -14px;
    border: 1px solid #0085ff;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background: #0085ff;
      svg {
        fill: white;
      }
    }
  }
  .title {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #363636;
    height: 50px;
  }

  .search {
    ::v-deep(.input-field-wrapper) {
      height: 40px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 10px;
    overflow: auto;
    max-height: 400px;
    li {
      font-size: 12px;
      line-height: 18px;
      color: #363636;
      height: 40px;
      display: grid;
      grid-auto-flow: column;
      justify-content: start;
      grid-column-gap: 20px;
      align-items: center;
      padding: 0 20px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
      }
      &:hover {
        background: rgba(38, 132, 254, 0.1);
        border-radius: 4px;
      }
    }
  }
}
</style>
