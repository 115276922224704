<template>
    <div class="release-block" :class="{'available': currentUserId && currentUserNewReleaseAvailable}">
        <div class="new-release" :class="{'bg-gray': listViewRoute}" v-if="currentUserId && currentUserNewReleaseAvailable">
            <div class="new-release__title">
                <span class="new-release__title__icon">
                    <svg width="16" height="16" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Warning / Info"><path id="Vector" d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z" stroke="#3B82F6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g></svg>
                </span>
                <span class="new-release__title__text">A new version {{ appVersion }} has been released!</span>
            </div>
            <div class="new-release__buttons">
                <div class="new-release__update" @click="handleNewReleaseAction('update')" >Update</div>
                <div class="new-release__close" @click="handleNewReleaseAction('close')">
                    <span><svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="Menu / Close_SM"><path id="Vector" d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16" stroke="#6B7280" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g></svg></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { useCookies } from "@/helpers/cookies";

export default {
    name: "NewRelease",
    computed: {
        ...mapGetters("profile", ["appVersion", "prevAppVersion", "currentUserId", "currentUserNewReleaseAvailable"]),

        listViewRoute() {
            return this.$route.name === 'wbs-project-list-view' || this.$route.name === 'wbs-template-list-view';
        }
    },
    methods: {
        ...mapActions("profile", ["acceptRelease"]),

        handleNewReleaseAction(type) {
            if (this.currentUserId) {
                const userId = this.currentUserId;

                if (type === 'releaseNotes') {
                    this.processAction(type);
                } else {
                    this.acceptRelease({ userId })
                        .then(response => {
                            this.processAction(type);
                        })
                        .catch(error => {
                            console.log('error', error);
                        });
                }
            }
        },

        processAction(type) {
            if (type === 'releaseNotes') {
                this.$router.push('/release-notes');
            } else if (type === 'update') {
                if (config.isLocal) {
                    window.location.reload();
                } else {
                    let isInsideJiraInstance = true;

                    if (typeof AP == 'undefined') {
                        isInsideJiraInstance = false;
                    }
                    if (isInsideJiraInstance) {
                        if (typeof AP.navigator == 'undefined') {
                            isInsideJiraInstance = false;
                        }
                    }

                    if (isInsideJiraInstance) {
                        const { saveCookie } = useCookies();

                        const newReleaseLastRoutePath = '/release-notes'; //this.$route.path;
                        saveCookie('newReleaseLastRoutePath', newReleaseLastRoutePath, 100)
                        AP.navigator.reload();
                    }
                }
            } else if (type === 'close') {

            }
        }
    },
}
</script>

<style lang="scss">
.release-block {

    &.available {
        padding: 10px 0 20px;
        z-index: 9999999;
        position: relative;
    }
}
.new-release {
    width: 500px;
    height: 50px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #F3F4F6;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.bg-gray {
        background-color: #f6f8fa;
    }

    &__title {
        height: 24px;
        color: #1F2937;
        margin-right: 25px;
        display: flex;
        align-items: center;

        &__icon {
            margin-right: 10px;
            display: flex;
            align-items: center;
        }

        &__text {
            font-weight: 500;
            font-size: 14px;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
    }

    &__notes {
        width: 125px;
        height: 24px;
        background: #FFFFFF;
        border: 1px solid #E5E7EB;
        border-radius: 4px;
        padding: 4px 12px;
        cursor: pointer;
        margin-right: 7px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        &__text {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #1F2937;
        }

        &.without-update {
            margin-right: 112px;
        }
    }

    &__update {
        width: 65px;
        height: 22px;
        border-radius: 4px;
        background: #3B82F6;
        padding: 4px 12px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #F9FAFB;
        cursor: pointer;
        margin-right: 40px;
        text-align: center;
        display: flex;
        align-items: center;
    }

    &__close {
        width: 25px;
        height: 25px;
        color: #6B7280;
        cursor: pointer;
    }
}
</style>
