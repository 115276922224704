<template>
  <div class="modal-body" data-testid="new-project-popup">
    <div class="close" @click="cancelModal">
      <icon-close :width="'10px'" :height="'10px'" :fill="'#0085FF'" />
    </div>
    <div class="title">
      <div>
        <strong>{{
           title !== ""
           ? translate("projects.edit_project") + " :"
           : translate("projects.new_project")


          }}
        </strong>
        {{  title  }}
      </div>
      <div v-if="!isFormRequiredFill">
        <small>{{  translate("modal.new_project.fill_required")  }}</small>
      </div>
    </div>
    <form @submit.prevent="submit" :class="{ 'has-error': isFormValid }">
      <div class="text">
        <div class="icon">
          <input type="file" name="fileName" hidden ref="fileName" @change="onChangeFileSelect" :accept="'.jpg,.png'" />
          <div class="photo" :class="{ 'has-photo': iconUri }" @click="openFileNameDialog">
            <img v-if="projectIcon" :src="projectIcon" alt="" />
            <template v-else>
              <icon-photo :width="'25px'" />
              <div class="icon-plus">
                <icon-plus :width="'5px'" />
              </div>
            </template>
          </div>
          <div v-if="isLargePhoto" class="error-photo">
            {{  translate("modal.error_photo_size", { count: 2 })  }}
          </div>
          <div class="text-company-icon">
            {{  translate("modal.new_project.company_icon")  }}
          </div>
          <div class="text-upload">{{  translate("modal.new_project.upload_image")  }}</div>
        </div>
        <div class="project-options">
          <label>
            {{  translate("projects.create_project_option")  }}
            <icon-attention :width="'20px'" :height="'20px'" />
            <div class="info-popup">
              <app-baloon>
                <template v-slot:title>
                  <strong class="title">
                    <icon-attention :fill="'rgb(0, 133, 255)'" :width="'20px'" :height="'20px'" />
                    {{  translate("modal.new_project.options_title")  }}
                  </strong>
                </template>
                <template v-slot:content>
                  <p>
                    <strong>{{  translate("modal.new_project.options_1_bold")  }}</strong>
                    {{  translate("modal.new_project.options_1_text")  }}
                  </p>
                  <p>
                    <strong>{{  translate("modal.new_project.options_2_bold")  }}</strong>
                    {{  translate("modal.new_project.options_2_text")  }}
                  </p>
                  <p>
                    <strong>{{  translate("modal.new_project.options_3_bold")  }}</strong>
                    {{  translate("modal.new_project.options_3_text")  }}
                  </p>
                  <p>
                    <strong>{{  translate("modal.new_project.options_4_bold")  }}</strong>
                    {{  translate("modal.new_project.options_4_text")  }}
                  </p>
                </template>
              </app-baloon>
            </div>
          </label>
          <base-select-box v-model="optionId" :options="optionList" :disabled="isDisableProjectOption"
            :placeholder="translate('select_option')" label-by="name" value-by="id" :min="1" :close-on-select="true"
            :small-font="true" />
          <small class="error-text">{{  invalidFields.get("createOption")  }}</small>
        </div>
        <div class="project-option">
          <template v-if="optionId == 'template'">
            <label>{{  translate("templates.create_from_template")  }}</label>
            <base-select-box class="template-drop-down" v-model="templateId" :options="templateList"
              :disabled="isCanChangeTemplate == false" :placeholder="translate('templates.select_template')"
              label-by="name" value-by="id" :min="1" :close-on-select="true" :small-font="true" :searchable="true"
              :clear-on-close="true" @input="handleInputTemplate" />
            <small class="error-text" v-if="!templateId">{{
               translate("modal.new_project.template_required")
              }}</small>
          </template>
          <template v-if="optionId == 'filter'">
            <label class="filter-label">
              {{  translate("import.select_jira_filter")  }}
              <div class="icon-import-error">
                <icon-error v-if="isImportError" :width="'18px'" :height="'18px'" :fill="'#d81d1d'"
                  @mouseenter="displayImportError = true" @mouseleave="displayImportError = false" />
              </div>
              <app-import-error v-if="displayImportError" />
            </label>
            <app-jira-filter class="filter-drop-down" v-model="jiraJql" :disabled="isDisableProjectOption"
              @change="filterValue = null">
              <base-select-box :style="{ width: '100%' }" class="filter-drop-down" v-model="filterValue"
                :options="filterList" :disabled="isDisableProjectOption"
                :placeholder="translate('import.select_filter')" label-by="name" value-by="id" :min="1"
                :close-on-select="true" :small-font="true" :searchable="true" :clear-on-close="true"
                @input="handleInputFilter" />
              <small class="error-text" v-if="!filterValue && !jiraJql">{{
                 translate("modal.new_project.filter_required")
                }}</small>
              <div class="enter-jql">
                {{  translate("modal.new_project.enter_jql")  }}
              </div>
            </app-jira-filter>
            <div class="jql-warning" v-if="isJqlWarning">
              <icon-error width="20px" height="20px" :fill="'#d81d1d'" />
              {{  translate("modal.new_project.jql_warning")  }}
            </div>
          </template>
        </div>
        <div class="project-manager">
          <label>{{  translate("modal.new_project.project_manager")  }}</label>
          <app-select-box-multiselect v-model="manager" :options="userList" :searchable="true"
            :loading="loadingSearchManager" :placeholder="translate('select_user')"
            :multipleLabel="() => translate('select_user')" @onApply="applyManagerFilter"
            @onSearch="(e) => handleInput(e, 'manager')">
            <template #icon>
              <icon-wrapper :fill="'#0085ff'">
                <icon-person v-if="!loadingSearchManager" :width="'18px'" :height="'18px'" :fill="'transparent'"
                  :stroke="'white'" />
                <icon-loader :width="'18px'" :height="'18px'" v-if="loadingSearchManager" />
              </icon-wrapper>
            </template>
            <template #option="item">
              <img v-if="item.option.iconUri" :src="item.option.iconUri" alt="" />
              {{  item.option.label  }}
            </template>
            <template #apply-button>
              <app-button class="apply-button" :color="'purple'" type="button">
                {{  translate("apply")  }}
              </app-button>
            </template>
          </app-select-box-multiselect>
        </div>
        <div class="status">
          <label>{{  translate("modal.new_project.status")  }}</label>
          <base-select-box v-model="statusId" :options="statusList" :placeholder="translate('projects.select_status')"
            :selectedText="translate('selected')" label-by="label" value-by="value" :min="1" :close-on-select="true"
            :small-font="true" />
          <small class="error-text">{{  invalidFields.get("status")  }}</small>
        </div>
        <div class="estimation">
          <label>{{  translate("general_settings.estimation_style")  }}</label>
          <base-select-box v-model="estimationType" :options="estimationStyleList"
            :placeholder="translate('general_settings.select_estimation')" :selectedText="translate('selected')"
            label-by="label" value-by="value" :min="1" :close-on-select="true" :small-font="true" />
          <small class="error-text">{{  invalidFields.get("estimationType")  }}</small>
        </div>
        <div class="name">
          <label>{{  translate("modal.new_project.project_name")  }}*</label>
          <base-input data-testid="project-name-input" :required="true" v-model="name" @input="clearErrors" />
          <small class="error-text">{{  invalidFields.get("name")  }}</small>
        </div>
        <div class="descr">
          <label>{{  translate("modal.new_project.description")  }}</label>
          <base-text-area v-model="description" />
        </div>
      </div>
      <div class="actions">
        <div class="user-access">
          <label>{{  translate("modal.new_project.user_access")  }}</label>
          <app-select-box-multiselect v-model="access" :options="userList" :searchable="true"
            :loading="loadingSearchAccess" :placeholder="translate('select_user')"
            :multipleLabel="() => translate('select_user')" @onApply="applyUsersFilter"
            @onSearch="(e) => handleInput(e, 'access')">
            <template #icon>
              <icon-wrapper :fill="'#0085ff'">
                <icon-person v-if="!loadingSearchAccess" :width="'18px'" :height="'18px'" :fill="'transparent'"
                  :stroke="'white'" />
                <icon-loader :width="'18px'" :height="'18px'" v-if="loadingSearchAccess" />
              </icon-wrapper>
            </template>
            <template #option="item">
              <img v-if="item.option.iconUri" :src="item.option.iconUri" alt="" />
              {{  item.option.label  }}
            </template>
            <template #apply-button>
              <app-button class="apply-button" :color="'purple'" type="button">
                {{  translate("apply")  }}
              </app-button>
            </template>
          </app-select-box-multiselect>
        </div>
        <div class="buttons">
          <app-button :color="'default'" type="button" @click="cancelModal">
            {{  translate("modal.cancel")  }}
          </app-button>
          <app-button data-testid="submit-project" :color="'primary'" :disabled="!isFormRequiredFill || isLoading"
            type="submit">
            <div class="loader" v-show="isProjectCreating">
              <icon-refresh :width="'21px'" :height="'21px'" />
            </div>
            {{  translate("modal.save")  }}
          </app-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { reactive, ref, toRefs, computed, onMounted, watch } from "vue";
import { useModalHelper } from "@/helpers/modalHelper";
import debounce from "lodash.debounce";
import AppButton from "@/components/shared/Button";
import IconClose from "@/components/icons/IconClose.vue";
import IconPhoto from "@/components/icons/IconPhoto.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import BaseInput from "@/components/forms/elements/BaseInput.vue";
import BaseTextArea from "@/components/forms/elements/BaseTextArea.vue";
import BaseSelectBox from "vue-select-box";
import IconPerson from "@/components/icons/IconPerson.vue";
import IconWrapper from "@/components/icons/IconWrapper.vue";
import { useStore } from "vuex";
import moment from "moment";
import errorToList from "@/helpers/errorToList";
import IconLoader from "@/components/icons/IconLoader.vue";
import IconRefresh from "@/components/icons/IconRefresh.vue";
import IconAttention from "@/components/icons/IconAttention.vue";
import AppBaloon from "@/components/shared/baloon/Baloon";
import AppJiraFilter from "@/components/forms/elements/JiraFilter.vue";
import AppImportError from "@/components/wbsProject/ImportError.vue";
import IconError from "@/components/icons/IconError.vue";
import AppSelectBoxMultiselect from "@/components/shared/SelectBoxMultiselect.vue";
import { useRouter } from "vue-router";
export default {
  components: {
    AppButton,
    IconClose,
    IconPhoto,
    IconPlus,
    BaseInput,
    BaseTextArea,
    BaseSelectBox,
    IconPerson,
    IconWrapper,
    IconLoader,
    IconRefresh,
    IconError,
    AppBaloon,
    IconAttention,
    AppJiraFilter,
    AppImportError,
    AppSelectBoxMultiselect,
  },
  inject: ["translate"],
  props: {
    project: {
      type: Object,
      default: function () {
        return {
          name: "",
          nodesCount: 0,
          status: "Active",
          modification: null,
          description: null,
          iconUri: null,
          members: [],
          jiraFilterId: null,
          createOption: "blank"
        }
      },
    },
    template: {
      type: Object,
      default: null,
    },
    countNodes: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const store = useStore(),
      router = useRouter(),
      cloneProps = JSON.parse(JSON.stringify(props.project)),
      { openModal, closeModal, submitModal } = useModalHelper(),
      fileName = ref(null),
      isLargePhoto = ref(false),
      isLoading = computed(() => store.getters["loader/isLoading"]),
      state = reactive({
        fromTemplate: props.template,
        iconUri: cloneProps.iconUri,
        projectIcon: cloneProps.iconUri,
        name: cloneProps.name,
        description: cloneProps.description,
        modification:
          cloneProps.modification || moment(new Date()).format("MMM DD, YYYY hh:mm A"),
        manager: [],
        access: [],
        selectedManagers: cloneProps.managers?.map(item => {
          return { ...item, ...{ value: item.id } }
        }) || [],
        isImportError: cloneProps.lastImportErrorMessage ? true : false,
        displayImportError: false,
        selectedUsersAccess: [],
        isDisableProjectOption: computed(() => {
          return false;
        }),
        filterValue: null,
        jiraJql: cloneProps?.jiraJql,
        isJqlWarning: computed(() => {
          return (

            (props.project.nodesCount > 0 || props.countNodes > 0) &&
            props.project?.jiraJql !== state.jiraJql
          );
        }),
        filterList: computed(() => {
          if (cloneProps.jiraFilterId) {
            const array = [
              ...store.getters["wbs/filterList"],
              {
                id: cloneProps.jiraFilterId,
                name: cloneProps.jiraFilter.name,
                jql: cloneProps.jiraFilter.jql,
              },
            ];
            return [
              ...new Set([...new Map(array.map((item) => [item["id"], item])).values()]),
            ];
          } else {
            return store.getters["wbs/filterList"];
          }
        }),
        optionId: cloneProps.createOption,
        optionList: [
          {
            id: "blank",
            name: "Create a blank document",
          },
          {
            id: "template",
            name: "Create project from Template",
          },
          {
            id: "filter",
            name: "Create project from Jira filter",
          },
        ],
        tempItemList: [],
        userList: computed(() => {
          const array = [
            ...store.getters["projects/userList"].slice(1),
            ...state.selectedUsersAccess,
            ...state.selectedManagers,
            ...state.tempItemList,
          ];
          if (state.fromTemplate && state.fromTemplate.members) {
            array.push(...state.fromTemplate.members?.map(item => {
              return { ...item, ...{ value: item.id } }
            }));
          }

          return [
            ...new Set([...new Map(array.map((item) => [item["label"], item])).values()]),
          ];
        }),
        statusId: computed(() => {
          return store.getters["projects/statusList"].find(
            (s) => s.label == cloneProps.status
          )?.value;
        }).value,
        statusList: computed(() => store.getters["projects/statusList"]),
        estimationType:
          cloneProps.estimationType ||
          computed(() => store.getters["profile/estimationType"]).value ||
          null,
        estimationStyleList: computed(() => store.getters["profile/estimationTypes"]),
        templateId: props.template ? props.template.id : null,
        isCanChangeTemplate: computed(() => {
          return true;
        }),
        templateList: computed(() => {
          if (state.fromTemplate) {
            const array = [
              ...store.getters["templates/searchTemplates"].map((t) => {
                return {
                  id: t.id,
                  name: t.name,
                  members: t.members,
                };
              }),
              {
                id: state.fromTemplate.id,
                name: state.fromTemplate.name,
                members: state.fromTemplate.members,
              },
            ];
            return [
              ...new Set([...new Map(array.map((item) => [item["id"], item])).values()]),
            ];
          } else
            return store.getters["templates/searchTemplates"].map((t) => {
              return {
                id: t.id,
                name: t.name,
                members: t.members,
              };
            });
        }),
        invalidFields: new Map(),
        backendErrors: [],
        isFormRequiredFill: computed(() => {
          return (
            state.name !== "" &&
            isLargePhoto.value == false &&
            state.selectedUsersAccess.length > 0 &&
            ((state.optionId == "template" && state.templateId !== null) ||
              (state.optionId == "filter" && state.filterValue !== null) ||
              state.jiraJql !== null ||
              state.optionId == "blank")
          );
        }),
        isFormValid: computed(() => {
          state.invalidFields.clear();
          state.backendErrors.forEach((error) => {
            state.invalidFields.set(error.name, error.text);
          });

          return state.invalidFields.size == 0;
        }),
        isProjectCreating: false,
      });

    const addUserAccess = () => {
      const user = store.getters["profile/user"];

      const array = [
        {
          value: user.id,
          label: user.displayName,
          iconUri: user.iconUri,
        },
      ];

      if (props.project.members.length) {
        array.push(...props.project.members.map(m => {
          return {
            value: m.id,
            label: m.label,
            iconUri: m.iconUri
          }
        }));
      }

      if (
        (!props.project.members.length || props.project.nodesCount == 0) &&
        state.fromTemplate &&
        state.fromTemplate.members &&
        state.optionId == "template"
      ) {
        state.fromTemplate.members.forEach((user) => {
          array.push({
            value: user.id,
            label: user.label,
            iconUri: user.iconUri
          });
        });
      }

      state.selectedUsersAccess = [
        ...new Set([...new Map(array.map((item) => [item["value"], item])).values()]),
      ];

      state.access = [...state.selectedUsersAccess.map((i) => i.value)]
      state.manager = [...state.selectedManagers.map((i) => i.value)]
    };

    const clearErrors = () => {
      state.invalidFields.clear();
      state.backendErrors = [];
    };

    const applyManagerFilter = (items) => {
      state.selectedManagers = items.map((u) => u);
      // add user access automatically
      state.selectedManagers.forEach(item => {
        if (!state.selectedUsersAccess.some(u => u.value == item.value)) {
          state.selectedUsersAccess.push(item)
          state.access.push(item.value)
        }
      })
    };

    const applyUsersFilter = (items) => {
      state.selectedUsersAccess = items.map((u) => u);
    };

    watch(
      () => state.templateId,
      (current) => {
        if (!current) return;
        state.fromTemplate = state.templateList.find((t) => t.id == current);
        state.optionId = 'template';
        addUserAccess();
      }, {
        immediate: true,
      }
    );

    watch(
      () => state.optionId,
      (current) => {
        if (current == "template" && state.templateList.length) {
          state.templateId = state.templateList[0].id;
          state.fromTemplate = state.templateList[0];
          addUserAccess();
        }
      }
    );

    watch(
      () => state.filterValue,
      (current) => {
        const jql = state.filterList.find((f) => f.id == current)?.jql;
        if (jql) state.jiraJql = jql;
      }
    );

    const submit = async () => {
      const projectData = {
        name: state.name,
        modification: state.modification,
        status: state.statusList.find((s) => s.value === state.statusId).label,
        description: state.description,
        nodes: 0,
        iconUri: state.iconUri,
        members: state.selectedUsersAccess.map(m => {
          return { ...m, ...{ id: m.value } }
        }),
        managers: state.selectedManagers.map(m => {
          return { ...m, ...{ id: m.value } }
        }),
        estimationType: state.estimationType,
        templateId: state.templateId,
        createOption: state.optionId,
        jiraFilterId: state.filterValue,
        jiraJql: state.jiraJql || null,
      };
      try {
        state.backendErrors = [];
        state.isProjectCreating = true;
        let response = null;

        if (props.project.id) {
          if (state.templateId && state.optionId == "template") {
            const template = state.templateList.find((t) => t.id == state.templateId);
            openModal("ProjectCreating", {
              projectData: {
                ...{ id: props.project.id },
                ...projectData,
              },
              templateName: template?.name,
            });
            retun;
          }
          response = await store.dispatch("projects/editProject", {
            ...{ id: props.project.id },
            ...projectData,
          });
        } else {
          if (state.templateId && state.optionId == "template") {
            const template = state.templateList.find((t) => t.id == state.templateId);
            openModal("ProjectCreating", { projectData, templateName: template?.name });
            retun;
          }
          response = await store.dispatch("projects/addProject", projectData);
        }
        state.isProjectCreating = false;
        submitModal({ ...projectData, ...{ id: response?.data?.data?.id } });
      } catch (error) {
        state.isProjectCreating = false;
        state.backendErrors = errorToList(error);
      }
    };

    const openFileNameDialog = () => {
      fileName.value.click();
    };

    const onChangeFileSelect = (e) => {
      if (fileName.value.files.length) {
        isLargePhoto.value = false;
        state.iconUri = fileName.value.files[0];
        state.projectIcon = URL.createObjectURL(state.iconUri);
        let size = fileName.value.files[0].size / 1024 / 1024;
        size = size.toFixed(2);
        if (size > 2) {
          isLargePhoto.value = true;
        }
      }
    };

    const loadingSearchManager = ref(false);
    const loadingSearchAccess = ref(false);
    const debounceSearch = debounce(async (inputEvent, target) => {
      if (target === "manager") loadingSearchManager.value = true;
      if (target === "access") loadingSearchAccess.value = true;
      await store.dispatch("projects/getUserList", {
        perPage: 5,
        search: inputEvent.target.value,
      });
      loadingSearchManager.value = false;
      loadingSearchAccess.value = false;
    }, 500);

    const debounceSearchFilter = debounce(async (inputEvent) => {
      await getFilterList({
        perPage: 5,
        search: inputEvent.target.value,
      });
    }, 500);

    const handleInputFilter = async (inputEvent) => {
      debounceSearchFilter(inputEvent);
    };

    const handleInput = async (inputEvent, target) => {
      debounceSearch(inputEvent, target);
    };

    const debounceSearchTemplate = debounce(async (inputEvent) => {
      await store.dispatch("templates/searchTemplates", {
        perPage: 5,
        search: inputEvent.target.value,
      });
    }, 500);

    const handleInputTemplate = async (inputEvent) => {
      debounceSearchTemplate(inputEvent);
    };

    const getFilterList = async (params = { perPage: 10, search: "" }) => {
      await store.dispatch("wbs/getFilterList", params);
      if (props.project.jiraFilterId) state.filterValue = props.project.jiraFilterId;
    };

    const getOptionList = async (value) => {
      switch (value) {
        case "template":
          await store.dispatch("templates/searchTemplates", { perPage: 5, search: "" });
          break;
        case "filter":
          await getFilterList();
          break;
        default:
          break;
      }
    };

    watch(
      () => state.optionId,
      (current) => {
        getOptionList(current);
      }
    );

    onMounted(async () => {
      state.filterValue = cloneProps.jiraFilterId ? cloneProps.jiraFilterId : null;
      addUserAccess();
      await store.dispatch("projects/getUserList", { perPage: 5, search: "" });
      await getOptionList(state.optionId);
    });

    const cancelModal = () => {
      closeModal();
      if (['projects'].includes(router.currentRoute.value.name)) {
        router.replace({ name: router.currentRoute.value.name, params: { openModal: false } })
      }
    }

    return {
      ...toRefs(state),
      submit,
      cancelModal,
      fileName,
      openFileNameDialog,
      applyManagerFilter,
      applyUsersFilter,
      onChangeFileSelect,
      isLargePhoto,
      clearErrors,
      isLoading,
      handleInput,
      loadingSearchManager,
      loadingSearchAccess,
      handleInputTemplate,
      handleInputFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  width: 620px;
  position: relative;

  .close {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -21px;
    top: -14px;
    border: 1px solid #0085ff;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      background: #0085ff;

      svg {
        fill: white;
      }
    }
  }

  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #363636;

    strong {
      font-weight: 600;
    }

    small {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #a1a9ba;
      margin-top: 5px;
    }
  }

  .text {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 5px;
    margin-top: 50px;
    grid-template-areas:
      "Icon Status"
      "Icon Estimation"
      "Icon Name"
      "Options Name"
      "Option Descr"
      "ProjectManager Descr"
      "Actions Actions";

    .icon {
      grid-area: Icon;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .photo {
      cursor: pointer;
      width: 85px;
      height: 85px;
      background: #f0f4f8;
      border: 1px dashed #0085ff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: relative;

      &.has-photo {
        border: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }

      .icon-plus {
        position: absolute;
        width: 17px;
        height: 17px;
        background: #0085ff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        bottom: 0;
        right: 0;
      }
    }

    .error-photo {
      font-size: 12px;
      text-align: center;
      margin-top: 15px;
      color: rgb(235, 117, 113);
      text-align: center;
    }

    .text-company-icon {
      grid-area: TextIcon;
      font-size: 12px;
      line-height: 18px;
      margin-top: 20px;
      text-align: center;
      color: #363636;
    }

    .text-upload {
      grid-area: TextUpload;
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      color: #c3cad9;
    }

    .project-options {
      grid-area: Options;

      label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        svg:hover~.info-popup {
          display: block;
        }
      }

      .info-popup {
        display: none;
        position: absolute;
        left: calc(100% + 18px);
        top: calc(100% - 30px);

        .title {
          display: grid;
          grid-template-columns: 30px 1fr;
          align-items: center;
        }
      }
    }

    .filter-label {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon-import-error {
        cursor: pointer;
        position: relative;
        top: 5px;
      }

      .info-content {
        position: absolute;
        top: calc(100% + 13px);
        left: calc(100% - 21px);
      }
    }

    .project-option {
      grid-area: Option;

      ::v-deep(.filter-select-box) {
        width: 100%;
      }
    }

    .project-manager {
      grid-area: ProjectManager;
    }

    .status {
      grid-area: Status;
    }

    .estimation {
      grid-area: Estimation;
    }

    .enter-jql {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      margin-top: 5px;
      margin-bottom: 0;
      color: #a1a9ba;
      display: block;
    }

    .name {
      grid-area: Name;

      ::v-deep(.input-field-wrapper) {
        height: 55px;
      }

      small {
        margin-top: 5px;
      }

      &.has-error {
        .input-field-wrapper {
          box-shadow: none;
          border: 1px solid rgb(255, 126, 126);
        }

        small {
          display: block;
        }
      }
    }

    .descr {
      grid-area: Descr;
    }

    textarea {
      border: 1px solid #c3cad9;
      box-sizing: border-box;
      border-radius: 8px;
      width: 100%;
      resize: none;
      outline: none;
    }
  }

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 5px;
    color: #a1a9ba;
    display: block;
  }

  .actions {
    grid-area: Actions;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    margin-top: 10px;

    .button {
      height: 55px;
      width: 100%;
      align-self: end;
    }

    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
    }

    .loader {
      display: flex;
      align-items: center;

      svg {
        animation: spin 1s linear infinite;
      }
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.error-text {
  color: red;
}

.select-box-wrapper {
  ::v-deep(.vue-select.disabled) {
    input {
      background: transparent;
    }

    opacity: 0.5;
  }
}

.jql-warning {
  padding: 5px;
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  gap: 10px;
  background: #ffeb3b;
  color: red;
  font-size: 14px;
  margin: 5px;
  border-radius: 5px;
}

::v-deep(.apply-button) {
  height: 35px !important;
  border-radius: 3px !important;
}
</style>
