<template>
  <div class="social-media">
    <a target="_blank" @click.stop="openLink('https://www.facebook.com/anetosoftwarellc/')">
      <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20 10.0251C20 4.49123 15.52 0 10 0C4.48 0 0 4.49123 0 10.0251C0 14.8772 3.44 18.9173 8 19.8496V13.0326H6V10.0251H8V7.5188C8 5.58396 9.57 4.01003 11.5 4.01003H14V7.01754H12C11.45 7.01754 11 7.46867 11 8.02005V10.0251H14V13.0326H11V20C16.05 19.4987 20 15.2281 20 10.0251Z"
          fill="#1877F2" />
      </svg>
    </a>
    <a target="_blank" href="https://twitter.com/anetosoftware" @click.stop="openLink">
      <svg width="20" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.46 2C20.69 2.35 19.86 2.58 19 2.69C19.88 2.16 20.56 1.32 20.88 0.31C20.05 0.81 19.13 1.16 18.16 1.36C17.37 0.5 16.26 0 15 0C12.65 0 10.73 1.92 10.73 4.29C10.73 4.63 10.77 4.96 10.84 5.27C7.28001 5.09 4.11001 3.38 2.00001 0.79C1.63001 1.42 1.42001 2.16 1.42001 2.94C1.42001 4.43 2.17001 5.75 3.33001 6.5C2.62001 6.5 1.96001 6.3 1.38001 6V6.03C1.38001 8.11 2.86001 9.85 4.82001 10.24C4.19074 10.4122 3.5301 10.4362 2.89001 10.31C3.16162 11.1625 3.69355 11.9084 4.41103 12.4429C5.1285 12.9775 5.99546 13.2737 6.89001 13.29C5.37364 14.4904 3.494 15.1393 1.56001 15.13C1.22001 15.13 0.880009 15.11 0.540009 15.07C2.44001 16.29 4.70001 17 7.12001 17C15 17 19.33 10.46 19.33 4.79C19.33 4.6 19.33 4.42 19.32 4.23C20.16 3.63 20.88 2.87 21.46 2Z"
          fill="#1D9BF0" />
      </svg>
    </a>
    <a target="_blank" href="https://www.linkedin.com/company/aneto-software/about/?viewAsMember=true" @click.stop="openLink">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.7778 0C18.3671 0 18.9324 0.234126 19.3491 0.650874C19.7659 1.06762 20 1.63285 20 2.22222V17.7778C20 18.3671 19.7659 18.9324 19.3491 19.3491C18.9324 19.7659 18.3671 20 17.7778 20H2.22222C1.63285 20 1.06762 19.7659 0.650874 19.3491C0.234126 18.9324 0 18.3671 0 17.7778V2.22222C0 1.63285 0.234126 1.06762 0.650874 0.650874C1.06762 0.234126 1.63285 0 2.22222 0H17.7778ZM17.2222 17.2222V11.3333C17.2222 10.3727 16.8406 9.45133 16.1613 8.77204C15.482 8.09274 14.5607 7.71111 13.6 7.71111C12.6556 7.71111 11.5556 8.28889 11.0222 9.15556V7.92222H7.92222V17.2222H11.0222V11.7444C11.0222 10.8889 11.7111 10.1889 12.5667 10.1889C12.9792 10.1889 13.3749 10.3528 13.6666 10.6445C13.9583 10.9362 14.1222 11.3319 14.1222 11.7444V17.2222H17.2222ZM4.31111 6.17778C4.80618 6.17778 5.28098 5.98111 5.63104 5.63104C5.98111 5.28098 6.17778 4.80618 6.17778 4.31111C6.17778 3.27778 5.34444 2.43333 4.31111 2.43333C3.81309 2.43333 3.33547 2.63117 2.98332 2.98332C2.63117 3.33547 2.43333 3.81309 2.43333 4.31111C2.43333 5.34444 3.27778 6.17778 4.31111 6.17778ZM5.85556 17.2222V7.92222H2.77778V17.2222H5.85556Z"
          fill="#1877F2" />
      </svg>
    </a>
    <a target="_blank" href="https://www.tiktok.com/@anetosoftware" @click.stop="openLink">
      <svg width="20" height="23" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.8041 9.00187C18.4092 10.1523 20.3756 10.8292 22.4994 10.8292V6.73178C22.0974 6.73187 21.6966 6.6898 21.3033 6.60627V9.83152C19.1797 9.83152 17.2136 9.15471 15.6081 8.00437V16.3661C15.6081 20.5491 12.2259 23.9398 8.05417 23.9398C6.49759 23.9398 5.05074 23.468 3.84891 22.6588C5.22064 24.0651 7.13361 24.9374 9.24991 24.9374C13.422 24.9374 16.8042 21.5467 16.8042 17.3635V9.00187H16.8041ZM18.2796 4.86808C17.4593 3.96954 16.9206 2.80833 16.8041 1.52454V0.997559H15.6706C15.956 2.6292 16.9292 4.02316 18.2796 4.86808ZM6.48753 19.4489C6.02922 18.8465 5.78147 18.1094 5.78259 17.3515C5.78259 15.4384 7.32955 13.8872 9.23813 13.8872C9.59376 13.887 9.9473 13.9418 10.2863 14.0495V9.86049C9.89014 9.80609 9.49036 9.7829 9.09075 9.79144V13.052C8.75158 12.9441 8.39786 12.8894 8.04206 12.8897C6.13356 12.8897 4.58668 14.4407 4.58668 16.3541C4.58668 17.707 5.35986 18.8783 6.48753 19.4489Z"
          fill="#FF004F" />
        <path
          d="M15.6081 8.00428C17.2136 9.15463 19.1796 9.83144 21.3033 9.83144V6.60618C20.1179 6.35299 19.0685 5.73196 18.2795 4.86808C16.929 4.02308 15.9559 2.62911 15.6706 0.997559H12.6935V17.3633C12.6867 19.2713 11.1424 20.8161 9.23794 20.8161C8.11577 20.8161 7.11873 20.2798 6.48734 19.4489C5.35984 18.8783 4.58658 17.707 4.58658 16.3542C4.58658 14.441 6.13345 12.8898 8.04195 12.8898C8.40762 12.8898 8.76005 12.9469 9.09065 13.052V9.79152C4.99212 9.87644 1.69598 13.234 1.69598 17.3634C1.69598 19.4248 2.51677 21.2935 3.84898 22.6589C5.05081 23.468 6.49757 23.94 8.05424 23.94C12.2261 23.94 15.6081 20.549 15.6081 16.3661V8.00437L15.6081 8.00428Z"
          fill="black" />
        <path
          d="M21.3033 6.60603V5.73414C20.2343 5.73576 19.1865 5.43562 18.2795 4.86802C19.0824 5.74923 20.1395 6.35692 21.3033 6.60621V6.60603ZM15.6705 0.997414C15.6434 0.841518 15.6225 0.684578 15.608 0.526983V0H11.4973V16.3659C11.4907 18.2737 9.94642 19.8185 8.04188 19.8185C7.50195 19.8193 6.96941 19.6927 6.48727 19.449C7.11865 20.2797 8.1157 20.8159 9.23787 20.8159C11.1422 20.8159 12.6867 19.2713 12.6934 17.3634V0.9975H15.6705V0.997414ZM9.09083 9.79138V8.86302C8.74733 8.81592 8.40103 8.79236 8.05434 8.7925C3.88207 8.7925 0.5 12.1834 0.5 16.3659C0.5 18.9883 1.8292 21.2993 3.84907 22.6587C2.51687 21.2934 1.69608 19.4246 1.69608 17.3633C1.69608 13.234 4.99213 9.87629 9.09083 9.79138Z"
          fill="#00F2EA" />
      </svg>
    </a>
    <a target="_blank" href="https://www.instagram.com/anetosoftware/" @click.stop="openLink">
      <svg width="17" height="17" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.375 9.5C12.375 10.0686 12.2064 10.6245 11.8905 11.0973C11.5746 11.5701 11.1256 11.9386 10.6002 12.1562C10.0749 12.3738 9.49681 12.4307 8.93912 12.3198C8.38142 12.2088 7.86914 11.935 7.46707 11.5329C7.06499 11.1309 6.79117 10.6186 6.68024 10.0609C6.56931 9.50319 6.62624 8.92512 6.84385 8.39978C7.06145 7.87445 7.42994 7.42543 7.90274 7.10953C8.37553 6.79362 8.93138 6.625 9.5 6.625C10.2618 6.62737 10.9917 6.93103 11.5303 7.46969C12.069 8.00834 12.3726 8.73823 12.375 9.5ZM18.4844 5.54688V13.4531C18.4844 14.7875 17.9543 16.0672 17.0108 17.0108C16.0672 17.9543 14.7875 18.4844 13.4531 18.4844H5.54688C4.2125 18.4844 2.93279 17.9543 1.98924 17.0108C1.0457 16.0672 0.515625 14.7875 0.515625 13.4531V5.54688C0.515625 4.2125 1.0457 2.93279 1.98924 1.98924C2.93279 1.0457 4.2125 0.515625 5.54688 0.515625H13.4531C14.7875 0.515625 16.0672 1.0457 17.0108 1.98924C17.9543 2.93279 18.4844 4.2125 18.4844 5.54688ZM13.8125 9.5C13.8125 8.64707 13.5596 7.81329 13.0857 7.1041C12.6118 6.39492 11.9383 5.84217 11.1503 5.51577C10.3623 5.18937 9.49522 5.10397 8.65867 5.27036C7.82213 5.43676 7.05372 5.84749 6.4506 6.4506C5.84749 7.05372 5.43676 7.82213 5.27036 8.65867C5.10397 9.49522 5.18937 10.3623 5.51577 11.1503C5.84217 11.9383 6.39492 12.6118 7.1041 13.0857C7.81329 13.5596 8.64707 13.8125 9.5 13.8125C10.6437 13.8125 11.7406 13.3581 12.5494 12.5494C13.3581 11.7406 13.8125 10.6437 13.8125 9.5ZM15.25 4.82812C15.25 4.61489 15.1868 4.40645 15.0683 4.22915C14.9498 4.05185 14.7815 3.91367 14.5845 3.83207C14.3875 3.75047 14.1707 3.72912 13.9615 3.77072C13.7524 3.81232 13.5603 3.915 13.4095 4.06578C13.2587 4.21655 13.1561 4.40866 13.1145 4.61779C13.0729 4.82693 13.0942 5.0437 13.1758 5.24071C13.2574 5.43771 13.3956 5.60609 13.5729 5.72455C13.7502 5.84302 13.9586 5.90625 14.1719 5.90625C14.4578 5.90625 14.732 5.79266 14.9342 5.59047C15.1364 5.38829 15.25 5.11406 15.25 4.82812Z"
          fill="url(#paint0_linear_257_113)" />
        <defs>
          <linearGradient id="paint0_linear_257_113" x1="9.5" y1="0.515625" x2="9.5" y2="18.4844"
            gradientUnits="userSpaceOnUse">
            <stop />
            <stop offset="0.0001" stop-color="#285AEB" />
            <stop offset="0.375" stop-color="#D6249F" />
            <stop offset="0.588542" stop-color="#FD5949" />
            <stop offset="0.9999" stop-color="#FDF497" />
            <stop offset="1" />
          </linearGradient>
        </defs>
      </svg>
    </a>
    <a target="_blank" href="https://www.youtube.com/channel/UCnTNgAl8qHXnHZ7yQRQFc-Q" @click.stop="openLink">
      <svg width="25" height="17" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.9167 13.3752L17.6204 9.50016L10.9167 5.62516V13.3752ZM25.8483 3.26141C26.0163 3.8685 26.1325 4.68225 26.21 5.71558C26.3004 6.74891 26.3392 7.64016 26.3392 8.41516L26.4167 9.50016C26.4167 12.3289 26.21 14.4085 25.8483 15.7389C25.5254 16.9014 24.7763 17.6506 23.6138 17.9735C23.0067 18.1414 21.8958 18.2577 20.1908 18.3352C18.5117 18.4256 16.9746 18.4643 15.5538 18.4643L13.5 18.5418C8.08793 18.5418 4.71668 18.3352 3.38626 17.9735C2.22376 17.6506 1.47459 16.9014 1.15168 15.7389C0.98376 15.1318 0.86751 14.3181 0.79001 13.2847C0.699594 12.2514 0.660843 11.3602 0.660843 10.5852L0.583344 9.50016C0.583344 6.67141 0.79001 4.59183 1.15168 3.26141C1.47459 2.09891 2.22376 1.34975 3.38626 1.02683C3.99334 0.858913 5.10418 0.742663 6.80918 0.665163C8.48834 0.574746 10.0254 0.535996 11.4463 0.535996L13.5 0.458496C18.9121 0.458496 22.2833 0.665163 23.6138 1.02683C24.7763 1.34975 25.5254 2.09891 25.8483 3.26141Z"
          fill="#EA4335" />
      </svg>
    </a>
  </div>
</template>
<script>
export default {
  setup() {
    const openLink = (link) => {
      window.open(link)
    }

    return {
      openLink,
    }
  }
}
</script>
<style lang="scss" scoped>
.social-media {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-top: 15px;
  border-top: 1px solid #F0F0F0;
  padding: 10px 0;
  border-bottom: 1px solid #F0F0F0;
  svg {
    width: 18px;
    height: 18px;
  }  
}
</style>