import { ref } from 'vue';
import { useStore } from 'vuex';

export function useJiraEditMetaService() {
    const store = useStore(),
        editmetaData = ref(null),
        timeTrackingField = ref(null),
        assigneeField = ref(null);

    async function getEditMeta(node) {

        let editmeta;

        try {
            editmeta = await AP.request(`/rest/api/3/issue/${node.jiraIssueKey}/editmeta`);
        } catch (error) {
            editmeta = null;
        }

        if (editmeta && editmeta.body) {
            editmetaData.value = JSON.parse(editmeta.body);
            const parsedEditMeta = JSON.parse(editmeta.body);
            const fields = parsedEditMeta.fields;

            timeTrackingField.value = fields['timetracking'] ?? null;
            assigneeField.value = fields['assignee'] ?? null;
        } else {
            editmetaData.value = null;
            timeTrackingField.value = null;
            assigneeField.value = null;
        }
    }

    return {
        getEditMeta,
        editmetaData,
        timeTrackingField,
        assigneeField
    };
}
