<template>
  <div id="app-access-message">
    <div class="wrapper">
      <div class="block-info">
        <div class="logo-name">
            <icon-app-logo :width="'125px'" :height="'40px'"  />
          <span class="name-app">Work Breakdown Structure for Jira</span>
        </div>
        <div class="ooops">OOOPS!</div>
        <p class="message">
          You don't seem to have access to this application. Please contact the
            <template v-if="firstAdminUserDisplayName">
                <strong style="font-weight: 600">{{ firstAdminUserDisplayName }}</strong>
            </template>
            <template v-else>
                person who installed it
            </template>
            so that they can add you and you can start
          enjoying Aneto WBS.
        </p>
      </div>
      <div class="wrapper-img">
        <img src="../../assets/images/welcome-screen-example.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import IconAppLogo from "../icons/appLogo.vue";

export default {
  components: {
      IconAppLogo,
  },
    props: {
        firstAdminUserDisplayName: {
            type: String,
            default: "",
        },
    },
};
</script>

<style lang="scss">
#app-access-message {
  padding: 30px;
  .wrapper {
    min-width: 1250px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 90px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #fff;
    backdrop-filter: blur(25px);
    border-radius: 10px;
    @media (max-width: 1280px) {
      padding: 40px;
    }
    @media (max-width: 1024px) {
      padding: 20px;
      flex-direction: column;
      justify-content: center;
    }
    .block-info {
      max-width: 420px;
      width: 100%;
      .logo-name {
        display: flex;
        align-items: center;
        margin-bottom: 55px;
        @media (max-width: 1024px) {
          justify-content: center;
        }
        svg,
        img {
          margin-right: 20px;
          max-width: 84px;
          width: 100%;
        }
        .name-app {
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
        }
      }
      .ooops {
        font-weight: 600;
        font-size: 96px;
        line-height: 1;
        color: #4c6bef;
        @media (max-width: 1024px) {
          text-align: center;
          font-size: 76px;
        }
      }
      .message {
        margin-top: 5px;
        margin-bottom: 0;
        font-weight: 300;
        font-size: 14px;
        line-height: 180%;
        color: #363636;
        @media (max-width: 1024px) {
          text-align: center;
        }
      }
    }
    .wrapper-img {
      img {
        max-width: 504px;
        width: 100%;
      }
      @media (max-width: 1024px) {
        margin-top: 50px;
      }
    }
  }
}
</style>
