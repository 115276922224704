export default {
  state: state => state,
  instance: state => state.instance,
  user: state => state.instance.user,
  showMainIntroScreen: state => state.instance.showMainIntroScreen,
  showSettingsIntroScreen: state => state.instance.showSettingsIntroScreen,
  showUserAccessIntroScreen: state => state.instance.showUserAccessIntroScreen,
  estimationTypes: state => state.instance.estimationTypes,
  estimationType: state => state.instance.estimationType,
  conversionScreenUsed: state => state.instance.conversionScreenUsed,
  isAdmin: state => state.instance.hasAdminAccess,
    hasAccess: state => state.instance.hasAccess,
    showAccessMessage: state => state.showAccessMessage,
  language: state => state.instance.systemLanguage || 'en',
  tenantId: state => state.instance.id,
    firstAdminUserDisplayName: state => state.instance.firstAdminUserDisplayName,
    appVersion: state => state.instance.appVersion,
    prevAppVersion: state => state.prevAppVersion,
    currentUserId: state => state.instance.user.id,
    currentUserNewReleaseAvailable: state => state.instance.user.newReleaseAvailable,
};
