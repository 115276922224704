<template>
  <div class="modal-body" data-testid="new-node-modal">
    <div class="close" @click="closeModal">
      <icon-close :width="'10px'" :height="'10px'" :fill="'#0085FF'" />
    </div>
    <div class="title">
      <div>
        {{
         currentNode
         ? translate("new_node.edit_node") + ": "
         : translate("new_node.new_node")

        }}

        <template v-if="currentNode">
          <img :src="currentNode.jiraIssueType.iconUri" alt="" />
          {{  currentNode.jiraIssueKey ? currentNode.jiraIssueKey + "-" : ""  }}
          {{  currentNode.summary  }}
        </template>

        <template v-else>
          {{  title  }}

          {{
           parentNode
           ? translate("child_of") +
           " " +
           parentNode?.jiraIssueKey +
           "-" +
           parentNode.summary
           : ""

          }}
        </template>
      </div>
    </div>
    <form class="new-node-container" :class="{ 'is-valid': isFormValid === true }" @submit.prevent="submit">
      <div class="half projects-list">
        <div class="input-field">
          <label>{{  translate("new_node.project")  }} <span>*</span></label>
          <base-select-box ref="selectProjectEl" data-testid="jira-project-dropdown" v-model="jiraProjectId" :options="jiraProjects"
            :placeholder="translate('new_node.select_project')" :search-placeholder="translate('search_selectbox')"
            :selectedText="translate('selected')" :disabled="isProjectSelectorDisabled || isNodeCreating || isLoading"
            group-by="isGroup" label-by="name" value-by="id" :clear-on-close="true" :close-on-select="true"
            :small-font="true" :searchable="true" :min="1" @input="handleInputProjects">
            <template #selected-item>
              <div class="selected-item-value" v-if="projectSelectedItem">
                <img :src="projectSelectedItem.avatarUrl" alt="" />{{
                 projectSelectedItem.name 
                }}
              </div>
            </template>
            <template v-slot:default="item">
              <div class="image-item" @click="changeProject(item)">
                <img v-if="item.option.avatarUrl" :src="item.option.avatarUrl" alt="" />
                {{  item.option.name  }}
              </div>
            </template>
          </base-select-box>
          <small class="error-text">
            {{  invalidFields.get("jiraProjectId")  }}
          </small>
        </div>
      </div>
      <div class="half issuetypes-list">
        <div class="input-field info-field select-issue-type">
          <label>{{  translate("new_node.issue_type")  }}<span>*</span></label>
          <base-select-box data-testid="jira-issue-dropdown" v-model="issueTypeId" :options="issueTypesList"
            :placeholder="translate('new_node.select_issue_type')" :search-placeholder="translate('search_selectbox')"
            :selectedText="translate('selected')" group-by="isGroup" label-by="name" value-by="id"
            :close-on-select="true" :small-font="true" :min="1" :disabled="
              isIssueTypeSelectorDisabled || isLoading || isNodeCreating
            ">
            <template #selected-item>
              <div class="selected-item-value" v-if="issueTypeSelected">
                <img :src="issueTypeSelected.iconUri" alt="" />{{
                 issueTypeSelected.name 
                }}
              </div>
            </template>
            <template v-slot:default="item">
              <div class="image-item">
                <img v-if="item.option.iconUri" :src="item.option.iconUri" alt="" />
                {{  item.option.name  }}
              </div>
            </template>
          </base-select-box>
          <div class="info">
            <a target="_blank"
              href="https://aneto.atlassian.net/secure/ShowConstantsHelp.jspa?decorator=popup#IssueTypes">
              <icon-question :width="'26px'" :height="'26px'" />
            </a>
          </div>
          <small class="info-descr">{{
             translate("new_node.issue_type_description") 
            }}</small>
          <small class="error-text">{{
             invalidFields.get("jiraIssueTypeId") 
            }}</small>
        </div>
      </div>
      <div class="half priorities-list">
        <div class="input-field">
          <label>{{  translate("new_node.priority")  }}</label>
          <base-select-box data-testid="jira-priority-dropdown" v-model="jiraPriorityId" :options="jiraPriorityList"
            :placeholder="translate('new_node.select_priority')" :search-placeholder="translate('search_selectbox')"
            :selectedText="translate('selected')" label-by="name" value-by="id" :clear-on-close="true"
            :close-on-select="true" :small-font="true" :searchable="true" :min="1"
            :disabled="isLoading || isNodeCreating">
          </base-select-box>
          <small class="error-text">{{
             invalidFields.get("jiraPriorityId") 
            }}</small>
        </div>
      </div>
      <div class="input-field summary">
        <label>{{  translate("new_node.summary")  }} <span>*</span> </label>
        <base-input data-testid="node-summary-input" ref="summaryEl" :required="true" v-model="summary"
          :disabled="isLoading || isNodeCreating" :hasError="invalidFields.get('summary')" />
        <small class="error-text">{{  invalidFields.get("summary")  }}</small>
      </div>
      <div class="input-field description">
        <label>{{  translate("new_node.description")  }}</label>
        <div class="editor" :disabled="isLoading || isNodeCreating">
          <quill-editor ref="editorEl" v-model:content="description" @update:content="updateEditor" contentType="html"
            theme="snow" />
        </div>
      </div>
      <div class="input-field assignee">
        <label>{{  translate("new_node.assignee")  }}</label>
        <base-select-box data-testid="node-assignee-dropdown" v-model="userId" :options="userList"
          :placeholder="translate('new_node.select_assignee')" :search-placeholder="translate('search_selectbox')"
          :selectedText="translate('selected')" group-by="isGroup" label-by="label" value-by="value"
          :clear-on-close="true" :close-on-select="true" :small-font="true" :searchable="true" :min="1"
          @input="handleInput" :disabled="isLoading || isNodeCreating">
          <template #selected-item>
            <div class="selected-item-value user" v-if="selectedUser">
              <img :src="selectedUser.iconUri" alt="" />{{  selectedUser.label  }}
            </div>
          </template>
          <template v-slot:default="item">
            <div class="image-item" @click="changeUser(item)">
              <img v-if="item.option.iconUri" :src="item.option.iconUri" alt="" />
              {{  item.option.label  }}
            </div>
          </template>
        </base-select-box>
        <app-button data-testid="node-assignee-to-me" type="button" color="default"
          :disabled="isLoading || isNodeCreating" @click="assignToMe">{{  translate("new_node.assign_to_me")  }}
        </app-button>
      </div>
      <div class="actions">
        <base-check-box v-model="oneMore" v-if="!currentNode">{{
           translate("new_node.create_another") 
          }}</base-check-box>
        <div v-else>&nbsp;</div>

        <app-button data-testid="node-submit" color="primary" :disabled="isSubmitDisabled">
          <div class="loader" v-show="isNodeCreating">
            <icon-refresh :width="'21px'" :height="'21px'" :stroke="'white'" />
          </div>
          {{
           currentNode ? translate("new_node.edit_node") : translate("create") 
          }}
        </app-button>
        <app-button color="default" @click="closeModal">{{
           translate("cancel") 
          }}</app-button>
      </div>
    </form>
  </div>
</template>

<script>
import {
  onMounted,
  reactive,
  toRefs,
  computed,
  ref,
  onUnmounted,
  nextTick,
  watch,
} from "vue";
import { useStore } from "vuex";
import debounce from "lodash.debounce";
import BaseSelectBox from "vue-select-box";
import { useModalHelper } from "@/helpers/modalHelper";
import { useAllowedIssueTypes } from "@/helpers/allowedIssueTypes";

import AppButton from "@/components/shared/Button.vue";
import BaseInput from "@/components/forms/elements/BaseInput.vue";
import BaseCheckBox from "@/components/forms/elements/BaseCheckBox.vue";
import IconQuestion from "@/components/icons/IconQuestion.vue";
import IconSaveDescription from "@/components/icons/IconSaveDescription.vue";
import IconClose from "@/components/icons/IconClose.vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import errorToList from "@/helpers/errorToList";
import IconRefresh from "@/components/icons/IconRefresh.vue";
import { useJiraTransform } from "@/helpers/jiraTransform";
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: "top-right" });

export default {
  components: {
    BaseSelectBox,
    AppButton,
    IconQuestion,
    IconSaveDescription,
    BaseInput,
    BaseCheckBox,
    QuillEditor,
    IconClose,
    IconRefresh,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    projectId: {
      type: Number,
      required: true,
    },
    jiraProject: {
      type: Object,
      default: null,
    },
    parentId: {
      type: Number,
      default: null,
    },
    currentNode: {
      type: Object,
      default: null,
    },
    parentNode: {
      type: Object,
      default: null,
    },
    jiraIssueTypeId: {
      type: Number,
      default: null,
    },
    isShowOnlyRootIssueType: {
      type: Boolean,
      default: false,
    },
    firstNode: {
      type: Object,
      default: null,
    },
  },
  inject: ["translate"],
  setup(props) {
    const { html2JiraMarkup, nodes2Html, html2Nodes } = useJiraTransform();
    const cloneNode = JSON.parse(JSON.stringify(props.currentNode));
    const selectProjectEl = ref(null);
    const store = useStore(),
      { closeModal, submitModal } = useModalHelper(),
      { allowedIssueTypes, allowedIssueTypesBefore } = useAllowedIssueTypes(),
      state = reactive({
        oneMore: false,
        userId: null,
        selectedUser: null,
        selectedProject: null,
        description: cloneNode ? cloneNode.descriptionHtml : "",
        userList: computed(() => {
          if (state.selectedUser) {
            const arr = [
              ...store.getters["users/userList"],
              state.selectedUser,
            ];
            return arr;
          } else return store.getters["users/userList"];
        }),
        jiraProjectId: null,
        projectSelectedItem: computed(() => {
          const item = state.jiraProjects.find(
            (t) => t.id == state.jiraProjectId
          );
          return item;
        }),
        jiraProjects: computed(() => {
          if (state.selectedProject) {
            const arr = [
              ...store.getters["wbs/jiraProjects"],
              state.selectedProject,
            ];

            return [
              ...new Set([
                ...new Map(arr.map((item) => [item["name"], item])).values(),
              ]),
            ];
          } else return store.getters["wbs/jiraProjects"];
        }),
        issueTypeId: null,
        issueTypeSelected: computed(() => {
          const item = state.issueTypesList.find(
            (t) => t.id == state.issueTypeId
          );
          return item;
        }),
        issueTypesListByProject: computed(()=>{
          const jiraProjectId = state.jiraProjects.find(p=>p.id==state.jiraProjectId)?.jiraProjectId;
          return store.getters['wbs/issueTypesByProject']?.[jiraProjectId] || [];           
        }),
        issueTypesList: computed(() => {          
          const hierarchy = store.getters["settings/hierarchy"];
          if (
            props.isShowOnlyRootIssueType &&
            hierarchy.length &&
            props.firstNode
          ) {
            const list = allowedIssueTypesBefore(
              props.firstNode.jiraIssueType.name
            );

            
            let arr =  [...list, ...state.issueTypesListByProject.filter(i=>list.map(({name})=>name).includes(i.name))];
            return [...new Set([...new Map(arr.map((item) => [item["name"], item])).values()])];
          }
          if (cloneNode && cloneNode.parentId && props.parentNode) {
            return allowedIssueTypes(
              props.parentNode.jiraIssueType.jiraIssueTypeId,
              cloneNode.parentId
            );
          } else {
            const list = allowedIssueTypes(
              props.jiraIssueTypeId,
              props.parentId
            );
            
            let arr =  [...list, ...state.issueTypesListByProject.filter(i=>list.map(({name})=>name).includes(i.name))];
            return [...new Set([...new Map(arr.map((item) => [item["name"], item])).values()])];
          }
        }),
        jiraPriorityId: cloneNode ? cloneNode.jiraPriorityId : null,
        jiraPriorityList: computed(() => {
          return store.getters["wbs/jiraPriorities"];
        }),
        isProjectSelectorDisabled: computed(() => {
          if (cloneNode?.jiraProjectId) return true;
          return state.issueTypesList.some((i) => i.subtask == 1)
            ? true
            : false;
        }),
        isSubmitDisabled: computed(
          () => !state.issueTypeId || !state.jiraProjectId || !state.summary
        ),
        isIssueTypeSelectorDisabled: ref(false),
        isLoading: false,
        isNodeCreating: false,
        summary: cloneNode ? cloneNode.summary : "",
        backendErrors: [],
        invalidFields: new Map(),
        isFormValid: computed(() => {
          state.invalidFields.clear();
          state.backendErrors.forEach((error) => {
            state.invalidFields.set(error.name, error.text);
          });
          return state.invalidFields.size == 0;
        }),
      });
    const summaryEl = ref(),
      editorEl = ref();
    const getUserList = async (params = { perPage: 5, search: "" }) => {
      await store.dispatch("users/getUsers", params);
    };

    const getProjectList = async (params = { perPage: 5, search: "" }) => {
      await store.dispatch("wbs/getJiraProjects", params);
    };

    const loadingSearch = ref(false);
    const debounceSearch = debounce(async (inputEvent) => {
      loadingSearch.value = true;
      await getUserList({
        perPage: 5,
        search: inputEvent.target.value,
      });
      loadingSearch.value = false;
    }, 500);

    const debounceSearchProjects = debounce(async (inputEvent) => {
      loadingSearch.value = true;
      await getProjectList({
        perPage: 5,
        search: inputEvent.target.value,
      });
      loadingSearch.value = false;
      nextTick(()=>{
        selectProjectEl.value.$el.querySelector('input').blur();
        const value = selectProjectEl.value.$el.querySelector('input').value;
        if (value) {
          setTimeout(()=>{
            selectProjectEl.value.$el.querySelector('input').focus();
            selectProjectEl.value.$el.querySelector('input').value = value
          }, 100) 
        }        
      })
    }, 500);

    const changeProject = async (item) => {
      state.selectedProject = item.option;
      await getProjectList();
    };

    const changeUser = async (item) => {
      state.selectedUser = item.option;
      await getUserList();
    };

    const handleInput = async (inputEvent) => {
      debounceSearch(inputEvent);
    };
    const handleInputProjects = async (inputEvent) => {
      debounceSearchProjects(inputEvent);
    };

    const assignToMe = () => {
      const currentUser = store.getters["profile/user"];
      state.userId = currentUser.id;
      state.selectedUser = {
        label: currentUser.displayName,
        value: currentUser.id,
        iconUri: currentUser.iconUri,
      };
    };

    const getMaxCodeOfAccount = () => {
      const incCode = (codeOfAccounts) => {
        if (codeOfAccounts.indexOf(".") !== -1) {
          const arr = codeOfAccounts.split(".");
          let inc = parseInt(arr[arr.length - 1]) + 1;
          arr[arr.length - 1] = inc;
          return arr.join(".");
        } else {
          return parseInt(codeOfAccounts) + 1;
        }
      };

      if (!props.parentId) return 0;

      const nodes = store.getters["wbs/nodes"].filter(
        (n) => n.parentId == props.parentId
      );

      if (!nodes.length) {
        const node = store.getters["wbs/nodes"].find(
          (n) => n.id == props.parentId
        );
        if (node.codeOfAccounts == '---')
          return '---';

        if (node.codeOfAccounts != "0")
          return node.codeOfAccounts + ".1";
        else
          return "1";
      }

      const lastNode = nodes.sort(function (a, b) {
        let codeAccountA = a.codeOfAccounts?.toString();
        let codeAccountB = b.codeOfAccounts?.toString();

        if (codeAccountA && codeAccountB) {
          if (codeAccountA.split('.').length) {
            codeAccountA = codeAccountA.split(".").pop()
          } else {
            if (a.isOrphaned) {
              codeAccountA = "0"
            }
          }

          if (codeAccountB.split('.').length) {
            codeAccountB = codeAccountB.split(".").pop()
          } else {
            if (b.isOrphaned) {
              codeAccountB = "0"
            }
          }

          return parseInt(codeAccountA) - parseInt(codeAccountB);
        }
      })[nodes.length - 1];

      let codeOfAccounts = lastNode.codeOfAccounts;
      return incCode(codeOfAccounts.toString());
    };

    const submit = async () => {
      try {
        if (state.isNodeCreating) return;
        let nodeData = {};
        let description = html2Nodes(editorEl.value.getHTML());
        state.backendErrors = [];
        if (props.currentNode) {
          state.isNodeCreating = true;
          let assignee = null;
          if (state.selectedUser) {
            assignee = {
              id: state.selectedUser.value,
              displayName: state.selectedUser.label,
              iconUri: state.selectedUser.iconUri,
            };
          }

          nodeData = {
            projectId: props.projectId,
            nodeId: props.currentNode.id,
            node: {
              ...props.currentNode,
              ...{
                descriptionHtml: state.description,
                descriptionMarkup: html2JiraMarkup(state.description),
                descriptionObject: description,
                assigneeId: state.userId,
                assignee: assignee,
                parentId: props.currentNode.parentId,
                projectId: props.projectId,
                jiraProjectId: state.jiraProjectId,
                jiraIssueTypeId: state.issueTypeId,
                jiraPriorityId: state.jiraPriorityId,
                summary: state.summary,
                childrenDisplayOption: "wbs",
              },
            },
          };

          store.dispatch("wbs/editNode", nodeData).catch(error=>{
            const list = errorToList(error);
            const messages = [];
            if (list.length) {
              list.forEach(item=>{
                messages.push(item?.text)
              })              
            }
            messages.forEach(m=>{
              toaster.error(m, {position: "top-right",});
            })            
          });
        } else {
          state.isNodeCreating = true;
          let assignee = null;
          if (state.selectedUser) {
            assignee = {
              id: state.selectedUser.value,
              displayName: state.selectedUser.label,
              iconUri: state.selectedUser.iconUri,
            };
          }

          nodeData = {
            id: new Date().getTime(),
            isTemporary: true,
            codeOfAccounts: getMaxCodeOfAccount(),
            topNode: !props.parentId ? true : false,
            isNew: true,
            descriptionHtml: state.description,
            descriptionMarkup: html2JiraMarkup(state.description),
            descriptionObject: description,
            assigneeId: state.userId,
            assignee: assignee,
            parentId: props.parentId,
            projectId: props.projectId,
            jiraProjectId: state.jiraProjectId,
            jiraIssueTypeId: state.issueTypeId,
            jiraIssueType: { id: state.issueTypeId },
            jiraIssueKey: "",
            jiraProjectUrl: null,
            jiraProject: {
              id: state.jiraProjectId,
            },
            visible: true,
            jiraPriorityId: state.jiraPriorityId,
            summary: state.summary,
            childrenDisplayOption: "wbs",
            topNodeId: props.firstNode ? props.firstNode.id : null,
            showChildren: false,
            x: 0,
            y: 0,
          };

          store.dispatch("wbs/createNewNode", nodeData).catch(error=>{
            const list = errorToList(error);
            const messages = [];
            if (list.length) {
              list.forEach(item=>{
                messages.push(item?.text)
              })              
            }
            messages.forEach(m=>{
              toaster.error(m, {position: "top-right",});
            })            
          });
        }

        store.commit("wbs/SET_LAST_ISSUE_TYPE", state.issueTypeId);
        state.isNodeCreating = false;
        if (!state.oneMore) submitModal(nodeData);
        else {
          state.summary = "";
          state.description = "";
          editorEl.value.pasteHTML("");
          setTimeout(() => {
            summaryEl.value.focus();
          }, 100);
        }
      } catch (error) {
        state.isNodeCreating = false;
        state.backendErrors = errorToList(error);
      }
    };

    const createEditor = async (node) => {
      try {
        if (!node || node.descriptionObject.length == 0) {
          node = {
            descriptionObject: {
              type: "doc",
              content: [],
            },
          };
        }
        if (editorEl.value)
          editorEl.value.pasteHTML(nodes2Html(node.descriptionObject));
      } catch (error) {
        console.log("Error convert description", error);
      }
    };

    // replace all images
    const editorContent = ref("");
    const updateEditor = (e) => {
      editorContent.value = e;
    };

    watch(
      () => editorContent.value,
      (current, old) => {
        if (current == old) return;
        let pattern = /<img[\s].+?>/gi;
        if (pattern.test(current)) {
          const html = current.replaceAll(pattern, "");
          editorEl.value.pasteHTML(html);
        }
      }
    );

    onMounted(async () => {
      state.isLoading = true;
      nextTick(() => {
        createEditor(cloneNode || null);
      });

      await store.dispatch("wbs/getJiraPriorities");
      await getProjectList();
      await getUserList();

      state.isLoading = false;
      if (cloneNode && cloneNode.assignee) {
        state.selectedUser = {
          label: cloneNode.assignee.displayName,
          value: cloneNode.assignee.id,
          iconUri: cloneNode.assignee.iconUri,
        };
        state.userId = cloneNode.assignee.id;
      }

      if (props.jiraProject) {
        state.selectedProject = props.jiraProject;
        state.jiraProjectId = props.jiraProject.id;
      }

      if (cloneNode && cloneNode.jiraIssueType) {
        if (!cloneNode.syncError) {
          state.isIssueTypeSelectorDisabled = true;
        }
        state.issueTypeId = cloneNode.jiraIssueType.id;
      }

      //state.jiraProjectId = props.jiraProject;
      state.jiraPriorityId = cloneNode ? cloneNode.jiraPriorityId : null;
    });

    watch(() => state.issueTypesList, (current) => {      
      if (current?.some((l) => l.id == store.getters["wbs/lastIssueTypeId"]))
        state.issueTypeId = store.getters["wbs/lastIssueTypeId"];
    }, {
      immediate: true,
    });

    watch(()=> state.jiraProjectId, async (projectId)=>{
      state.isLoading = true
      if (projectId) {
        const jiraProjectId = state.jiraProjects.find(p=>p.id==projectId)?.jiraProjectId;
        if (jiraProjectId)
          await store.dispatch("wbs/getIssueTypesByProject", jiraProjectId)                  
      }        
      state.isLoading = false;  
    }, {
      immediate: true,
    })

    return {
      ...toRefs(state),
      changeUser,
      changeProject,
      handleInput,
      handleInputProjects,
      closeModal,
      assignToMe,
      submit,
      summaryEl,
      editorEl,
      updateEditor,
      selectProjectEl,
    };
  },
};
</script>
<style lang="scss" scoped>
.modal-body {
  width: 500px;
  position: relative;

  .close {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -21px;
    top: -10px;
    border: 1px solid #0085ff;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      background: #0085ff;

      svg {
        fill: white;
      }
    }
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    /* identical to box height, or 26px */

    display: flex;
    align-items: center;

    padding-bottom: 10px;
    margin-bottom: 10px;

    color: #14142b;
    border-bottom: 1px solid rgba(160, 163, 189, 0.15);
  }

  @media (max-width: 1500px) {
    width: 600px;
  }
}

.new-node-container {
  background: white;

  .input-field+.input-field {
    margin-top: 0px;
  }

  .projects-list {
    grid-area: ProjectsList;
  }

  .issuetypes-list {
    grid-area: IssueTypesList;
  }

  .priorities-list {
    grid-area: PrioritiesList;
  }

  .summary {
    grid-area: Summary;
  }

  .description {
    grid-area: Description;
  }

  .assignee {
    grid-area: Assignee;
  }

  .actions {
    grid-area: Actions;
  }

  .input-field.info-field {
    grid-template-columns: 265px 30px;
  }

  @media (max-width: 1500px) {
    .half .select-box-wrapper {
      width: 100%;
    }

    .info-descr {
      display: none;
    }

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "ProjectsList ProjectsList"
    "IssueTypesList PrioritiesList"
    "Summary Summary"
    "Description Description"
    "Assignee Assignee"
    "Actions Actions";
  }
}

.half {
  .select-box-wrapper {
    max-width: 320px;
  }
}

.input-field {
  .error-text {
    color: red;
  }

  .select-box-wrapper {
    ::v-deep(.vue-select-header) {
      height: 45px;
    }

    ::v-deep(.vue-select.disabled .vue-select-header) {
      background: #f1f1f1;
    }

    ::v-deep(.vue-select.disabled .vue-input) {
      background: #f1f1f1;
    }

    ::v-deep(.vue-select.disabled .vue-input input) {
      background: #f1f1f1;
    }

    ::v-deep(.vue-select.disabled .selected-item-show) {
      background: #f1f1f1;
    }

    .image-item {
      width: 100%;
    }
  }

  label {
    display: block;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    /* identical to box height, or 16px */

    display: flex;
    align-items: center;

    color: #828499;

    span {
      color: red;
    }
  }

  &.info-field {
    display: grid;
    grid-template-columns: 320px 30px;
    align-items: center;
    grid-column-gap: 15px;

    label {
      grid-area: 1 / 1;
    }

    .select-box-wrapper {
      grid-area: 2 / 1 / 2 / 1;
    }

    small {
      grid-area: 3/1/3/4;
    }

    small+small {
      grid-area: 4/1/4/4;
    }

    .info {
      grid-area: 2/2/2/2;
    }
  }

  &.assignee {
    display: grid;
    grid-template-columns: 1fr 135px;
    grid-column-gap: 10px;

    label {
      grid-area: 1 / 1;
    }

    .select-box-wrapper {
      grid-area: 2 / 1 / 2 / 1;
    }

    button {
      grid-area: 2/2/2/2;
      height: 45px;
      align-self: end;
    }
  }

  &.description {
    .editor {
      height: 100px;
      margin-bottom: 50px;
    }

    .editor[disabled="true"] {
      background: #f1f1f1;
      pointer-events: none;

      * {
        pointer-events: none;
      }

      ::v-deep(.ql-editor) {
        background: #f1f1f1;
      }
    }

    .controls {
      margin-top: 55px;
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 10px;
      justify-content: start;
    }
  }

  small {
    margin-top: 10px;
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 130%;
    color: #a0a3bd;
  }

  .selected-item-value {
    width: 100%;
    height: 37px;
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-column-gap: 15px;
    align-items: center;
    padding-left: 15px;
    padding-top: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    img {
      width: 16px;
      height: 16px;
    }

    &.user {
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }
  }
}

.actions {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 135px 135px;
  grid-column-gap: 10px;
  justify-content: end;
  align-items: center;

  .checkbox {
    justify-self: end;
  }

  .loader {
    display: flex;
    align-items: center;

    svg {
      animation: spin 1s linear infinite;
    }
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}

hr {
  border: 0;
  border-bottom: 1px solid rgba(160, 163, 189, 0.15);
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
