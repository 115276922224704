export default {
	openModal(state, { component, props }) {
		state.component = component;
		state.props = props;
	},

	setProps(state, payload = {}) {
		state.props = { ...state.props, ...payload }
	},

	setData(state, payload = {}) {
		state.data = payload
	},

	closeModal(state) {
		state.component = null;
	},

    setNestedData(state, payload = {}) {
        state.data = payload
    }
};
