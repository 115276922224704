<template>
  <div class="modal-body">
    <div class="close" @click="cancelModal">
      <icon-close :width="'10px'" :height="'10px'" :fill="'#0085FF'" />
    </div>
    <div class="title">
      <div>
        <strong>{{
            title !== ""
              ? translate("templates.edit_template") + " :"
              : translate("templates.add_template")
        }}
        </strong>
        {{ title }}
      </div>
      <div v-if="!isFormRequiredFill">
        <small>{{ translate("modal.new_template.fill_required") }}</small>
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="text">
        <div class="icon">
          <input type="file" name="fileName" hidden ref="fileName" @change="onChangeFileSelect" :accept="'.jpg,.png'" />
          <div class="photo" :class="{ 'has-photo': iconUri }" @click="openFileNameDialog">
            <img v-if="projectIcon" :src="projectIcon" alt="" />
            <template v-else>
              <icon-photo :width="'25px'" />
              <div class="icon-plus">
                <icon-plus :width="'5px'" />
              </div>
            </template>
          </div>
          <div v-if="isLargePhoto" class="error-photo">
            {{ translate("modal.error_photo_size", { count: 2 }) }}
          </div>
          <div class="text-company-icon">
            {{ translate("modal.new_template.company_icon") }}
          </div>
          <div class="text-upload">
            {{ translate("modal.new_template.upload_image") }}
          </div>
        </div>
        <div class="name" :class="{ 'has-error': isFormValid == false }">
          <label>{{ translate("modal.new_template.template_name") }}*</label>
          <base-input :required="true" v-model="name" @input="clearErrors" />
          <small>{{ invalidFields.get("name") }}</small>
        </div>
        <div class="status">
          <label>{{ translate("modal.new_template.status") }}</label>
          <base-select-box v-model="statusId" :options="statusList" :placeholder="translate('templates.select_status')"
            :selectedText="translate('selected')" :min="1" label-by="label" value-by="value" :close-on-select="true"
            :small-font="true" />
          <small class="error-text">{{ invalidFields.get("status") }}</small>
        </div>

        <div class="descr">
          <label>{{ translate("modal.new_template.description") }}</label>
          <base-text-area v-model="description" />
        </div>
      </div>
      <div class="actions">
        <div class="user-access">
          <label>{{ translate("modal.new_template.user_access") }}*</label>
          <app-select-box-multiselect
            v-model="access"
            :options="userList"
            :searchable="true"
            :loading="loadingSearchAccess"
            :placeholder="translate('select_user')"
            :multipleLabel="() => translate('select_user')"
            @onApply="applyUsersFilter"
            @onSearch="handleInput"
          >
            <template #icon>
              <icon-wrapper :fill="'#0085ff'">
                <icon-person v-if="!loadingSearchAccess" :width="'18px'" :height="'18px'" :fill="'transparent'"
                  :stroke="'white'" />
                <icon-loader :width="'18px'" :height="'18px'" v-if="loadingSearchAccess" />
              </icon-wrapper>
            </template>
            <template #option="item">
              <img v-if="item.option.iconUri" :src="item.option.iconUri" alt="" />
              {{ item.option.label }}
            </template>
            <template #apply-button>
              <app-button class="apply-button" :color="'purple'" type="button">
                {{ translate("apply") }}
              </app-button>
            </template>
          </app-select-box-multiselect>
        </div>
        <div class="buttons">
          <app-button :color="'default'" type="button" @click="cancelModal">
            {{ translate("modal.cancel") }}
          </app-button>
          <app-button :color="'primary'" :disabled="!isFormRequiredFill || isLoading" type="submit">{{
              translate("modal.save")
          }}</app-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { reactive, ref, toRefs, computed, onMounted, watch } from "vue";
import debounce from "lodash.debounce";
import { useModalHelper } from "@/helpers/modalHelper";
import AppButton from "@/components/shared/Button";
import IconClose from "@/components/icons/IconClose.vue";
import IconPhoto from "@/components/icons/IconPhoto.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import BaseInput from "@/components/forms/elements/BaseInput.vue";
import BaseTextArea from "@/components/forms/elements/BaseTextArea.vue";
import BaseSelectBox from "vue-select-box";
import IconPerson from "@/components/icons/IconPerson.vue";
import IconWrapper from "@/components/icons/IconWrapper.vue";
import { useStore } from "vuex";
import moment from "moment";
import errorToList from "@/helpers/errorToList";
import IconLoader from "@/components/icons/IconLoader.vue";
import AppSelectBoxMultiselect from "@/components/shared/SelectBoxMultiselect.vue";
import { useRouter } from "vue-router";
export default {
  components: {
    AppButton,
    IconClose,
    IconPhoto,
    IconPlus,
    BaseInput,
    BaseTextArea,
    BaseSelectBox,
    IconPerson,
    IconWrapper,
    IconLoader,
    AppSelectBoxMultiselect,
  },
  inject: ["translate"],
  props: {
    template: {
      type: Object,
      default: () => {
        return {
          name: "",
          nodes: 0,
          status: "Active",
          modification: null,
          description: null,
          iconUri: null,
          members: [],
        }
      },
    },
    title: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const store = useStore(),
      router = useRouter(),
      cloneProps = JSON.parse(JSON.stringify(props.template)),
      { closeModal, submitModal } = useModalHelper(),
      fileName = ref(null),
      isLargePhoto = ref(false),
      isLoading = computed(() => store.getters["loader/isLoading"]),
      state = reactive({
        projectIcon: cloneProps.iconUri,
        iconUri: cloneProps.iconUri,
        name: cloneProps.name,
        description: cloneProps.description,
        modification:
          cloneProps.modification || moment(new Date()).format("MMM DD, YYYY hh:mm A"),
        access: [],
        selectedUsersAccess: cloneProps.members?.map(item=>{
          return {...item, ...{ value: item.id }}
        }) || [],
        tempItemList: [],
        userList: computed(() => {
          const array = [
            ...store.getters["templates/userList"].slice(1),
            ...state.selectedUsersAccess,
            ...state.tempItemList,
          ].map((u) => {
            return {
              iconUri: u.iconUri,
              label: u.label,
              value: u.value,
            };
          });

          return [
            ...new Set([...new Map(array.map((item) => [item["label"], item])).values()]),
          ];
        }),
        statusId: computed(() => {
          return store.getters["templates/statusList"].find(
            (s) => s.label == cloneProps.status
          )?.value;
        }).value,
        statusList: computed(() => {
          return store.getters["templates/statusList"];
        }),
        invalidFields: new Map(),
        backendErrors: [],
        isFormRequiredFill: computed(() => {
          return (
            state.name !== "" &&
            isLargePhoto.value == false &&
            state.selectedUsersAccess.length > 0
          );
        }),
        isFormValid: computed(() => {
          state.invalidFields.clear();
          state.backendErrors.forEach((error) => {
            state.invalidFields.set(error.name, error.text);
          });
          return state.invalidFields.size == 0;
        }),
      });


    const clearErrors = () => {
      state.invalidFields.clear();
      state.backendErrors = [];
    };
    const applyManagerFilter = () => {
      state.selectedManagers = state.manager.map((u) =>
        state.userList.find((ul) => ul.value == u)
      );
    };

    const applyUsersFilter = (items) => {
      state.selectedUsersAccess = items.map((u) => u);
    };

    const submit = async () => {
      const templateData = {
        name: state.name,
        modification: state.modification,
        status: state.statusList.find((s) => s.value === state.statusId).label,
        description: state.description,
        nodes: 0,
        iconUri: state.iconUri,
        members: state.selectedUsersAccess.map(m => {
          return { ...m, ...{ id: m.value } }
        }),
      };

      try {
        state.backendErrors = [];
        let response;
        if (props.template.id) {
          response = await store.dispatch("templates/editTemplate", {
            ...{ id: props.template.id },
            ...templateData,
          });
        } else {
          response = await store.dispatch("templates/addTemplate", templateData);
        }
        submitModal({ ...templateData, ...{ id: response.data.data.id } });
      } catch (error) {
        state.backendErrors = errorToList(error);
      }
    };

    const openFileNameDialog = () => {
      fileName.value.click();
    };

    const onChangeFileSelect = (e) => {
      if (fileName.value.files.length) {
        isLargePhoto.value = false;
        state.iconUri = fileName.value.files[0];
        state.projectIcon = URL.createObjectURL(state.iconUri);
        let size = fileName.value.files[0].size / 1024 / 1024;
        size = size.toFixed(2);
        if (size > 2) {
          isLargePhoto.value = true;
        }
      }
    };

    const loadingSearchAccess = ref(false);
    const debounceSearch = debounce(async (inputEvent) => {
      loadingSearchAccess.value = true;
      await store.dispatch("templates/getUserList", {
        perPage: 5,
        search: inputEvent.target.value,
      });
      loadingSearchAccess.value = false;
    }, 500);

    const handleInput = async (inputEvent) => {
      debounceSearch(inputEvent);
    };

    onMounted(() => {
      store.dispatch("templates/getUserList", { perPage: 5, search: "" });
      if (state.selectedUsersAccess.length == 0) {
        const user = store.getters["profile/user"];
        state.selectedUsersAccess.push({
          value: user.id,
          label: user.displayName,
          iconUri: user.iconUri,
        });
        state.access.push(user.id)
      } else {
        state.access = state.selectedUsersAccess.map(u => u.value);
      }
    });
    const cancelModal = () => {
      closeModal();
      if (['templates'].includes(router.currentRoute.value.name)) {
        router.replace({ name: router.currentRoute.value.name, params: { openModal: false } })
      }
    }
    return {
      ...toRefs(state),
      submit,
      cancelModal,
      fileName,
      openFileNameDialog,
      applyManagerFilter,
      applyUsersFilter,
      onChangeFileSelect,
      isLargePhoto,
      clearErrors,
      isLoading,
      loadingSearchAccess,
      handleInput,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  width: 520px;
  position: relative;

  .close {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -21px;
    top: -14px;
    border: 1px solid #0085ff;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      background: #0085ff;

      svg {
        fill: white;
      }
    }
  }

  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #363636;

    strong {
      font-weight: 600;
    }

    small {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #a1a9ba;
      margin-top: 5px;
    }
  }

  .text {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    margin-top: 50px;
    grid-template-areas:
      "Icon Status"
      "Icon Descr"
      "Name Descr"
      "Actions Actions";

    .icon {
      grid-area: Icon;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .photo {
      cursor: pointer;
      width: 85px;
      height: 85px;
      background: #f0f4f8;
      border: 1px dashed #0085ff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      position: relative;

      &.has-photo {
        border: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }

      .icon-plus {
        position: absolute;
        width: 17px;
        height: 17px;
        background: #0085ff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        bottom: 0;
        right: 0;
      }
    }

    .error-photo {
      font-size: 12px;
      text-align: center;
      margin-top: 15px;
      color: rgb(235, 117, 113);
      text-align: center;
    }

    .text-company-icon {
      grid-area: TextIcon;
      font-size: 12px;
      line-height: 18px;
      margin-top: 20px;
      text-align: center;
      color: #363636;
    }

    .text-upload {
      grid-area: TextUpload;
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      color: #c3cad9;
    }

    .status {
      grid-area: Status;
    }

    .name {
      grid-area: Name;

      ::v-deep(.input-field-wrapper) {
        height: 55px;
      }

      small {
        margin-top: 5px;
        display: none;
        color: rgb(255, 126, 126);
      }

      &.has-error {
        .input-field-wrapper {
          box-shadow: none;
          border: 1px solid rgb(255, 126, 126);
        }

        small {
          display: block;
        }
      }
    }

    .descr {
      grid-area: Descr;

      ::v-deep(.input-field-wrapper) {
        height: 125px;
      }
    }

    textarea {
      border: 1px solid #c3cad9;
      box-sizing: border-box;
      border-radius: 8px;
      width: 100%;
      resize: none;
      outline: none;
    }
  }

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 5px;
    color: #a1a9ba;
    display: block;
  }

  .actions {
    grid-area: Actions;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    margin-top: 10px;

    .button {
      height: 55px;
      width: 100%;
      align-self: end;
    }

    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
    }
  }

  .error-text {
    color: red;
  }
}
::v-deep(.apply-button) {
  height: 35px !important;
  border-radius: 3px!important;
}
</style>
