export function usePositionHelper() {
  const calcElementPosition = ({ e, popupWidth, container, popupHeight }) => {
    const containerTop = container.getBoundingClientRect().top;
    const containerLeft = container.getBoundingClientRect().left;
    const path = e.path || (e.composedPath && e.composedPath());
    let { top, height, left } = path[0].getBoundingClientRect();
    let leftPosition = left - containerLeft;
    let topPosition = top + window.scrollY + height - 8;
    while (leftPosition + popupWidth + containerLeft > window.innerWidth) {
      leftPosition--;
    }
    if (popupHeight) {
      while (topPosition + popupHeight + containerTop > window.innerHeight) {
        topPosition--
      }      
    }
    return {
      position: "absolute",
      display: "block",
      zIndex: 999,
      left: `${leftPosition}px`,
      top: `${topPosition}px`,
    };
  };
  const getOffsetRect = (element) => {
    let box = element.getBoundingClientRect()

    let scrollTop = window.pageYOffset
    let scrollLeft = window.pageXOffset

    let top = box.top + scrollTop
    let left = box.left + scrollLeft

    return { top: Math.round(top), left: Math.round(left) }
  }
  /**
   * @param event {MouseEvent}
   * @param element {HTMLElement}
   * @return {{x: number, y: number}}
   */
  const getMousePosition = (element, event) => {
    let mouseX = event.pageX || event.clientX + document.documentElement.scrollLeft
    let mouseY = event.pageY || event.clientY + document.documentElement.scrollTop

    let offset = getOffsetRect(element)
    let x = mouseX - offset.left
    let y = mouseY - offset.top

    return [x, y];
  }

  return {
    getMousePosition,
    calcElementPosition
  }
}