import * as types from '@/store/mutation-types';
export default {
  [types.SET_PROFILE_INSTANCE](state, payload = {}) {
    state.instance = payload.data;
    state.instance.estimationTypes = Object.keys(state.instance.estimationTypes).map((k) => {
      return { value: k, label: state.instance.estimationTypes[k] };
    });
  },
  [types.SET_LANGUAGE](state, payload = 'en') {
    state.instance.systemLanguage = payload;
  },
  [types.SET_SHOW_USER_ACCESS_INTRO](state, payload = false) {
    state.instance.showUserAccessIntroScreen = payload
  },
  [types.SET_SHOW_MAIN_INTRO](state, payload = false) {
    state.instance.showMainIntroScreen = payload
  },
  [types.SET_CONVERSION_SCREEN_USED](state, payload) {
    state.instance.conversionScreenUsed = payload;
  },
    [types.SET_SHOW_ACCESS_MESSAGE] (state, showAccessMessage) {
        state.showAccessMessage = showAccessMessage;
    },
    [types.SET_APP_VERSION] (state, appVersion) {
        state.instance.appVersion = appVersion;
    },
    [types.SET_PREV_APP_VERSION] (state, prevAppVersion) {
        state.prevAppVersion = prevAppVersion;
    },
    [types.SET_CURRENT_USER_NEW_RELEASE_AVAILABLE] (state, newReleaseAvailable) {
        state.instance.user.newReleaseAvailable = newReleaseAvailable;
    }
};
