import * as types from '@/store/mutation-types';
import * as wbsConst from '@/store/modules/wbs/const';
import { useCookies } from '@/helpers/cookies';
import {ADD_CACHED_TIMETRACKING, SET_MODEL_META_LAST_IMPORT_ERROR_MESSAGE} from "../../mutation-types";
export default {
  [types.SET_STATUS_LIST](state, payload = { data: [] }) {
    state.statusList = payload.data;
    state.statusList = state.statusList.map(s => {
      return {
        label: s.name,
        value: s.name
      }
    })
  },
  [types.SET_NODES](state, payload) {
    const flatNodes = [];
    const convertToFlat = (nodes) => {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        flatNodes.push(node);
        if (node.children) {
          convertToFlat(node.children)
        }
      }
    }

    convertToFlat(payload.data);

    flatNodes.forEach(node => {
      node.x = 0;
      node.y = 0;
      node.showChildren = node.wbsChildrenCollapsed ? false : true;
      if (node.topNode)
        node.visible = true;
      if (node.parentId) {
        const parent = flatNodes.find(n => n.id == node.parentId);
        if (parent && parent.visible && !parent.wbsChildrenCollapsed) {
          node.visible = true;
        }
      }
    });

    state.nodes = flatNodes.map(n => {
      return { ...n };
    });

    let links = [];
    links = state.nodes.reduce((acc, node, i) => {
      acc = acc || [];
      if (node.parentId) {
        acc.push({
          id: i,
          from: node.parentId,
          to: node.id
        });
      }
      return acc;
    }, []);
    state.links = links;
    // add error nodes
    state.nodes.filter(n => n.syncError == true).forEach(node => {
      state.errorNodes.push({ id: node.id, action: 'edit', error: node.syncErrorMessage });
    })
  },
  [types.SET_NODES_META](state, nodesMeta) {
    state.nodesMeta = nodesMeta;
  },
    [types.SET_MODEL_META_LAST_IMPORT_ERROR_MESSAGE](state, payload) {
      state.nodesMeta.modelMeta.lastImportErrorMessage = payload;
    },
  [types.SET_PROJECT_FILTERS](state, payload = { data: {} }) {
    state.wbsState.filters = payload.data;
  },
  [types.SET_IMPORT_LIST](state, payload = { data: [] }) {
    state.importList = payload.data;
  },
  [types.SET_FILTER_LIST](state, payload = { data: [] }) {
    state.filterList = payload.data;
  },
  async [types.SET_WBS_STATE](state, payload) {
    state.wbsState = payload;
    const defaultValues = [
      { displayOption: 'wbs' },
      { displayTreeOption: 'horizontal' },
      { locationX: wbsConst.LocationX },
      { locationY: wbsConst.LocationY },
      { filters: { "projectId": [], "issueTypeId": [], "statusId": [], "assignee": [], "categoryId": [] } }
    ];

    defaultValues.forEach(v => {
      const k = Object.keys(v)[0];
      if (!state.wbsState[k]) {
        state.wbsState[k] = v[k];
      }
    });

    const { readCookie } = useCookies();
    const filter = await readCookie(`filter-${payload.id}`);
    if (filter)
      state.wbsState.filters = JSON.parse(filter);
    else
      state.wbsState.filters = defaultValues.filters;
  },
  [types.RESET_WBS_STATE](state) {
    state.wbsState = {}
  },
  [types.SET_JIRA_PROJECTS](state, payload = []) {
    state.jiraProjects = payload;
  },
  [types.SET_JIRA_STATUSES](state, payload = []) {
    state.jiraStatuses = payload;
  },
  [types.SET_JIRA_CATEGORIES](state, payload = []) {
    state.jiraCategories = payload;
  },
  [types.SET_JIRA_PRIORITIES](state, payload = []) {
    state.jiraPriorities = payload;
  },
  [types.SET_WBS_SOURCE](state, payload = []) {
    state.source = payload;
  },
  [types.SET_LAST_ISSUE_TYPE](state, payload = []) {
    state.lastIssueTypeId = payload;
  },
  [types.SET_IMPORT_RUN](state, payload = false) {
    state.activeImport = payload;
  },
  [types.SET_EXISTS_IMPORT](state, payload) {
    state.existsImport = payload;
  },
  [types.SET_PRINT_RUN](state, payload = false) {
    state.isPrintRun = payload;
  },
  [types.SET_PROJECT_PARTICIPANTS](state, payload = []) {
    state.projectParticipants = payload;
  },
    [types.ADD_PROJECT_PARTICIPANT](state, payload) {
      const exists = state.projectParticipants.some(p => p.user.id == payload.user.id);
        if (exists) return;

        state.projectParticipants.push(payload);
    },
    [types.DELETE_PROJECT_PARTICIPANT](state, payload) {
        state.projectParticipants = state.projectParticipants.filter(p => p.user.id !== payload.user.id);
    },
  [types.ADD_CACHED_NODE_STATUS](state, payload) {
    const exists =
      state.cachedNodeStatuses.some(
        status => status.projectId == payload.projectId
          && status.issueTypeId == payload.issueTypeId
      );
    if (exists) return;
    state.cachedNodeStatuses.push(payload);
  },
    [types.ADD_CACHED_TIMETRACKING](state, payload) {
    const exists =
                state.cachedTimeTracking.some(
                    timetracking => timetracking.projectId == payload.projectId
                        && timetracking.issueTypeId == payload.issueTypeId
                );
            if (exists) return;
            state.cachedTimeTracking.push(payload);
    },
    [types.ADD_CACHED_ASSIGNEE](state, payload) {
const exists =
                state.cachedAssignee.some(
                    assignee => assignee.projectId == payload.projectId
                        && assignee.issueTypeId == payload.issueTypeId
                );
            if (exists) return;
            state.cachedAssignee.push(payload);
    },
  [types.CLEAR_CACHED_STATUS](state) {
    state.cachedNodeStatuses = [];
  },
  [types.CLEAR_CACHED_NODE_STATUS](state, nodeId) {
    state.cachedNodeStatuses = state.cachedNodeStatuses.filter(s => s.nodeId !== nodeId);
  },
  [types.CLEAR_PROJECT_STATE](state) {
    state.wbsState = {};
  },
  [types.PUSH_NODE](state, payload = []) {
    state.nodes.push(payload)
  },
  [types.UPDATE_NODES](state, payload = []) {
    state.nodes = payload;
  },
  [types.SET_CACHED_NODES](state, { projectId, nodes }) {
    state.cachedNodes[projectId] = nodes;
  },
  [types.RESET_CACHED_NODES](state) {
    state.cachedNodes = {};
    state.nodes = [];
    state.links = [];
  },
  [types.UPDATE_NODE](state, payload = { id, createdNode }) {
    const node = state.nodes.find(n => n.id == payload.id);
    if (!node) return;

    Object.keys(payload.createdNode).forEach(k => {
      node[k] = payload.createdNode[k]
    });

    state.nodes.forEach(n => {
      if (n.parentId == payload.id) {
        n.parentId = payload.createdNode.id
      }
    })

    let links = [];
    links = state.nodes.reduce((acc, node, i) => {
      acc = acc || [];
      if (node.parentId) {
        acc.push({
          id: i,
          from: node.parentId,
          to: node.id
        });
      }
      return acc;
    }, []);
    state.links = links;
  },
  [types.UPDATE_NODE_LIST_COLLAPSE](state, { id, childrenCollapsed }) {
    let node = state.nodes.find(n => n.id == id);
    if (node) {
      node.listChildrenCollapsed = childrenCollapsed
    }
  },
  [types.UPDATE_LINKS](state, payload = []) {
    state.links = payload;
  },
  [types.ADD_ERROR_NODE](state, payload = []) {
    state.errorNodes.unshift(payload);
    state.errorNodes.forEach(errorNode => {
      const node = state.nodes.find(n => n.id == errorNode.id);
      if (node && node.isTemporary) {

        state.nodes.forEach(n => {
          if (n.parentId == node.id) {
            n.parentId = errorNode.createdNode.id
          }
        })

        Object.keys(errorNode.createdNode).forEach(k => {
          node[k] = errorNode.createdNode[k]
        });
        errorNode.id = node.id;
      }
    })
    let links = [];
    links = state.nodes.reduce((acc, node, i) => {
      acc = acc || [];
      if (node.parentId) {
        acc.push({
          id: i,
          from: node.parentId,
          to: node.id
        });
      }
      return acc;
    }, []);
    state.links = links;
  },
  [types.CLEAR_ERROR_NODES](state, payload = []) {
    state.errorNodes = [];
  },
  [types.DELETE_ERROR_NODE](state, payload) {
    state.errorNodes = state.errorNodes.filter(n => n.id !== payload);
  },
  [types.UPDATE_LAST_UPDATE_MILLESECONDS](state, { id, ms }) {
    const node = state.nodes.find(n => n.id == id);
    if (node) {
      node.updatedAtMs = ms;
    }
  },
  [types.SET_CURRENT_JIRA_TICKET](state, payload) {
    state.currentJiraTicket = payload;
  },
  [types.SET_ISSUETYPES_BY_PROJECT](state, { projectId, data }) {
    state.issueTypesByProject[projectId] = state.issueTypesByProject[projectId] || {}
    state.issueTypesByProject[projectId] = data;
  }
};
