<template>
  <div v-click-outside="(e) => onClickOutSide(e)" class="drop-down drop-down-status" :style="position">
    <div class="select-status">
      <div class="loader" v-if="isLoading">
        <icon-refresh :stroke="'#0f8af9'" :width="'21px'" :height="'21px'" />loading...
      </div>
      <base-select-box ref="selectBox" v-model="statusId" :options="statusList"
        :placeholder="translate('templates.select_status')" :selectedText="translate('selected')" label-by="name"
        value-by="id" :min="1" :close-on-select="true" :small-font="true" @change="onChangeItem"
        @onChangeFocus="onChangeFocus">
      </base-select-box>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref, watch, nextTick, computed } from "vue";
import BaseSelectBox from "vue-select-box";
import { useStore } from "vuex";
import IconRefresh from "@/components/icons/IconRefresh.vue";
export default {
  components: {
    BaseSelectBox,
    IconRefresh,
  },
  inject: ["translate"],
  props: {
    position: {
      type: Object,
      default: () => { },
    },
    status: {
      type: Number,
      default: null,
    },
    projectId: {
      type: Number,
      required: true,
    },
    issueTypeId: {
      type: Number,
      default: null,
    },
    nodeId: {
      type: Number,
      required: true,
    },
      jiraProjectKey: {
          type: String,
          default: ''
      },
      jiraIssueId: {
          type: Number,
          default: null
      },
      node: {
            type: Object,
            default: null
      }
  },
  emits: ["onChange", "onClickOutSide", "onDisableByAccess"],
  setup(props, { emit }) {
    const store = useStore(),
      state = reactive({
        statusId: null,
        isLoading: false,
        item: computed(
          () => state.statusList.find((s) => s.id == state.statusId)
        ),
        statusName: computed(
          () => state.statusList.find((s) => s.id == state.statusId)?.name
        ),
        statusList: [],
          accessProblem: false,
          mounted: false
      });

    const selectBox = ref();

    watch(
      () => props.position?.display,
      async () => {
          if (state.mounted) {
              state.statusList = [];

              await loadStatuses();
          }

        if (selectBox.value) selectBox.value.selectBox.isFocusing = true;
        state.statusId = props.status;
      }
    );

    const onChangeItem = (e) => {
      if (state.statusId)
        emit("onChange", {
            id: state.statusId,
            name: state.statusName,
            categoryColor: state.item.categoryColor,
            jiraIssueStatusId: state.item.jiraIssueStatusId
        });
    };

    const onChangeFocus = (value) => {
      if (!value) emit("onChange", null);
    };

    const loadStatuses = async () => {
        const statusList = store.getters["wbs/cachedNodeStatuses"].find(
            (status) =>
                status.projectId == props.projectId &&
                status.issueTypeId == props.issueTypeId
        );

        if (statusList) {
            state.statusList = statusList.list;
            state.accessProblem = statusList.accessProblem;
        } else {
            let jiraIssueTransitions = {};

            try {
                jiraIssueTransitions = await AP.request('/rest/api/3/issue/' + props.jiraIssueId + '/transitions', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (jiraIssueTransitions) {
                    jiraIssueTransitions = JSON.parse(jiraIssueTransitions.body);
                }
            } catch (e) {
                console.error('loadStatuses jiraIssueTransitions error', e);
                jiraIssueTransitions = {};
            }

            state.isLoading = true;
            const response = await store.dispatch("wbs/getJiraIssueStatusNode", {
                projectId: props.projectId,
                nodeId: props.nodeId,
            });

            state.isLoading = false;
            if (jiraIssueTransitions?.transitions) {
                state.accessProblem = false;
                state.statusList = response?.data?.filter(responseItem =>
                    jiraIssueTransitions?.transitions.some(
                        transitionItem => responseItem.jiraIssueStatusId == transitionItem.to.id
                    )
                ) || [];

                store.commit("wbs/ADD_CACHED_NODE_STATUS", {
                    projectId: props.projectId,
                    issueTypeId: props.issueTypeId,
                    accessProblem: state.statusList.length < 1,
                    list: state.statusList,
                });

                if (state.statusList.length < 1) {
                    emit('onDisableByAccess', {
                        id: props.nodeId,
                        accessProblem: true
                    })
                }
            } else {
                state.accessProblem = true;
                state.statusList = [];

                store.commit("wbs/ADD_CACHED_NODE_STATUS", {
                    projectId: props.projectId,
                    issueTypeId: props.issueTypeId,
                    accessProblem: true,
                    list: state.statusList,
                });

                emit('onDisableByAccess', {
                    id: props.nodeId,
                    accessProblem: true
                })
            }
        }
    };

    onMounted(async () => {
        await loadStatuses();

      if (selectBox.value) selectBox.value.selectBox.isFocusing = true;

      state.mounted = true;
    });

    const onClickOutSide = (e) => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (
        path.some((p) => {
          return (
            p.classList &&
            (p.classList.contains("drop-down-status") ||
            p.classList.contains("cell-value"))
          );
        })
      )
        return;

      emit('onClickOutSide')
    }

    return {
      ...toRefs(state),
      selectBox,
      onChangeItem,
      onChangeFocus,
      onClickOutSide,
    };
  },
};
</script>

<style lang="scss" scoped>
.select-status {
  position: relative;
}

.drop-down {
  background: #fff;
  position: relative;
  border-radius: 10px;

  ::v-deep(.vue-select-header) {
    visibility: hidden;
    width: 200px;
  }

  ::v-deep(.vue-dropdown) {
    margin-top: 0;
    top: 0 !important;
  }

  ::v-deep(.vue-dropdown-item) {
    position: relative;
  }

  .list-item {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    height: 100%;
    position: absolute;
    left: 0;
    padding-left: 15px;
    top: 0;
  }
}

.loader {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  svg {
    animation: spin 1s linear infinite;
    margin-right: 10px;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
