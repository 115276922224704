<template>
    <div
        class="node-container"
        :data-testid="isRoot ? 'root-node' : 'node'"
        :style="nodeStyle"
        :draggable="isOrphaned ? true : false"
        :id="id"
        @dragenter.prevent="dragenter"
        @dragstart="dragStart"
        @dragover.prevent
        @drop="drop"
        @dragleave="dragleave"
        @mousedown="handleMouseDown"
        @mouseup="handleMouseUp"
        @mouseover="handleMouseOver"
        @mouseleave="handleMouseLeave"
        :class="{
            selected: isSelected,
            'is-orphaned': isOrphaned,
            'is-root': isRoot,
        }"
    >
        <div
            v-if="isParent"
            :class="{
                'is-parent': childrenCount > 0,
                horizontal: config?.treeView == 'horizontal',
                vertical: config?.treeView == 'vertical',
            }"
            class="node-port node-input"
            @mousedown="inputMouseDown"
            @mouseup="inputMouseUp"
        ></div>

        <div
            :class="{
                'is-parent': childrenCount > 0,
                horizontal: config?.treeView == 'horizontal',
                vertical: config?.treeView == 'vertical',
            }"
            class="node-port node-output"
            v-if="childrenCount > 0"
            @mousedown="outputMouseDown"
        ></div>
        <div
            v-show="isDelete"
            class="node-delete"
            @click="(e) => deleteNode({ id, e })"
            :class="{ 'is-orphaned': isOrphaned }"
        >
            &times;
        </div>
        <slot />
    </div>
</template>

<script>
import { computed, toRefs, reactive, inject } from "vue";
export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
        x: {
            type: Number,
            default: 0,
        },
        y: {
            type: Number,
            default: 0,
        },
        type: {
            type: String,
            default: "Default",
        },
        label: {
            type: String,
            default: "input name",
        },
        centerX: {
            type: Number,
            default: 0,
        },
        centerY: {
            type: Number,
            default: 0,
        },
        scale: {
            type: Number,
            default: 1,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        edited: {
            type: Object,
            default: null,
        },
        childrenCount: {
            type: Number,
            default: 0,
        },
        isParent: {
            type: Boolean,
            default: false,
        },
        isOrphaned: {
            type: Boolean,
            default: false,
        },
        isUpdate: {
            type: Boolean,
            default: false,
        },
        isRoot: {
            type: Boolean,
            default: false,
        },
        subTask: {
            type: Boolean,
            default: false,
        },
        jiraIssueAvailabilityProblem: {
            type: Boolean,
            default: false,
        }
    },
    emits: [
        "nodeSelected",
        "nodeUnSelected",
        "linkingStart",
        "linkingStop",
        "onDragEnter",
        "onDrop",
        "onDragleave",
        "onDeleteNode",
    ],
    setup(props, { emit }) {
        let config;
        if (props.isOrphaned) {
            config = {};
        } else {
            config = inject("treeConfig");
        }

        const data = reactive({
            isDelete: false,
        });

        const nodeStyle = computed(() => {
            return {
                top: props.centerY + props.y * props.scale + "px",
                left: props.centerX + props.x * props.scale + "px",
                transform: `scale(${props.scale})`,
            };
        });

        const handleMouseDown = (e) => {
            if (props.edited || props.jiraIssueAvailabilityProblem) return;

            const target = e.target || e.srcElement;
            if (
                target.className.indexOf &&
                target.className.indexOf("node-input") < 0 &&
                target.className.indexOf("node-output") < 0
            ) {
                emit("nodeSelected", e);
            }
            //e.preventDefault();
        };

        const handleMouseUp = (e) => {
            emit("nodeUnSelected", e);
        };

        const handleMouseOver = () => {
            if (props.edited || props.jiraIssueAvailabilityProblem) return;
            data.isDelete = true;
        };
        const handleMouseLeave = () => {
            data.isDelete = false;
        };
        const outputMouseDown = (e) => {
            emit("linkingStart");
            e.preventDefault();
        };
        const inputMouseDown = (e) => {
            e.preventDefault();
        };
        const inputMouseUp = (e) => {
            emit("linkingStop");
            e.preventDefault();
        };

        const dragStart = (ev) => {
            if (props.subTask == true) {
                ev.preventDefault();
                return;
            }
            ev.dataTransfer.setData("id", ev.target.id);
        };

        const drop = (ev) => {
            const data = ev.dataTransfer.getData("id");
            emit("onDrop", parseInt(data));
        };

        const dragenter = (ev) => {
            const path = ev.path || (ev.composedPath && ev.composedPath());
            const el = path.find((e) => e.classList.contains("node-container"));
            emit("onDragEnter", parseInt(el?.id || ""));
        };

        const dragleave = (ev) => {
            emit("onDragleave", ev);
        };

        const deleteNode = (data) => {
            emit("onDeleteNode", data);
        };

        return {
            ...toRefs(data),
            handleMouseDown,
            handleMouseUp,
            handleMouseOver,
            handleMouseLeave,
            outputMouseDown,
            inputMouseDown,
            inputMouseUp,
            nodeStyle,
            config,
            dragStart,
            drop,
            dragenter,
            dragleave,
            deleteNode,
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$themeColor: #2684fe;
$portSize: 12;

.node-container {
    margin: 0;
    position: absolute;
    box-sizing: border-box;
    border: none;
    z-index: 1;
    cursor: move;
    transform-origin: top left;
    &.is-orphaned {
        .node-port {
            display: none;
        }
    }

    &.is-root {
        .node-input {
            display: none;
        }
    }
    .node-main {
        text-align: center;
        .node-type {
            background: $themeColor;
            color: white;
            font-size: 13px;
            padding: 6px;
        }
        .node-label {
            font-size: 13px;
        }
    }
    .node-port {
        position: absolute;
        width: #{$portSize}px;
        height: #{$portSize}px;
        &.horizontal {
            top: 50%;
            transform: translate(-50%, -50%);
        }
        &.vertical {
            left: 50%;
            transform: translate(-50%, -50%);
        }

        border-radius: 100px;
        background: #2684fe;
        z-index: 1;
    }

    .node-output {
        background: transparent;
        &.horizontal {
            right: -12px;
            top: 50%;
            left: auto;
        }
        &.vertical {
        }
    }

    .node-delete {
        position: absolute;
        right: -6px;
        top: -6px;
        font-size: 12px;
        width: 15px;
        height: 15px;
        color: #2684fe;
        cursor: pointer;
        background: white;
        border-radius: 100px;
        line-height: 16px;
        border: 1px solid #2684fe;
        text-align: center;
        z-index: 1;
        display: flex;
        align-items: self-end;
        justify-content: center;
        &.is-orphaned {
            right: 5px;
        }
    }
}
[draggable] {
    user-select: none;
    -khtml-user-drag: element;
    opacity: 1 !important;
}
.selected {
    outline: 1px solid #0f8af9;
    border-radius: 4px;
}
</style>
