import axios from 'axios';
import * as types from '@/store/mutation-types';
import errorToObject from "@/helpers/errorToObject";
export default {
  getNodesHierarchy({ commit }, fetchJiraIssueTypes = true) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/settings/nodes?fetchJiraIssueTypes=${fetchJiraIssueTypes}`)
        .then((response) => {
          const data = response.data;
          commit(types.SET_HIERARCHY, data);
          resolve(response);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  getIssueTypes({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/jira/issue-types`)
        .then((response) => {
          const data = response.data;
          commit(types.SET_ISSUE_TYPES, data);
          resolve(response);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  saveNodesHierarchy({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/settings/nodes`, payload)
        .then((response) => {
          const data = response.data;
          commit(types.SET_HIERARCHY, data);
          resolve(response);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  deleteNode({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/settings/nodes/${id}`)
        .then((response) => {
          const data = response.data;
          commit(types.SET_HIERARCHY, data);
          resolve(response);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  setGeneralSettings({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/settings/general`, payload)
        .then((response) => {
          const data = response.data;
          commit(types.SET_GENERAL_SETTINGS, data);
          resolve(response);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  getGeneralSettings({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/settings/general`)
        .then((response) => {
          const data = response.data;
          commit(types.SET_GENERAL_SETTINGS, data);
          resolve(response);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
};
