<template>
	<div id="navSidebar" ref="navBar" class="sidebar" data-testid="sidenav">
		<div class="logo-search">
			<div class="logo" :class="{ 'mini-logo': collapsed }">
				<icon-app-logo :width="collapsed ? '80px' : '160px'" height="80px" />
			</div>
			<div class="search-create">
				<app-search-pages v-if="collapsed == false" />
				<app-top-menu :position="collapsed ? 'right' : 'left'" />
			</div>
		</div>
		<!-- Toggle button -->
		<button type="button" class="toggle-btn" @click="toggleNav">
			<i class="arrow" :class="isOpen ? 'left' : 'right'">
				<icon-arrow stroke="#23252A" />
			</i>
		</button>

		<!-- Side navigation -->
		<div class="navigation" :class="{ collapsed: collapsed }">
			<div class="main-menu">
				<router-link :to="{ name: 'dashboard.welcome' }" class="main-menu__dashboard-item" :class="{
					opened: isOpen,
					'router-link-active':
						$route.name == 'index' ||
						$route?.name?.indexOf('dashboard') !== -1,
				}">
					<icon-dashboard :width="'18px'" />{{
							isOpen ? translate("sidebar.dashboard") : ""
					}}
				</router-link>
				<router-link :to="{ name: 'projects' }" class="main-menu__projects-item" :class="{
					opened: isOpen,
					'router-link-active': $route?.name?.indexOf('wbs-project') !== -1,
				}">
					<icon-bag width="18px" />{{
							isOpen ? translate("sidebar.projects") : ""
					}}
				</router-link>
				<router-link :to="{ name: 'templates' }" class="main-menu__templates-item" :class="{
					opened: isOpen,
					'router-link-active': $route?.name?.indexOf('wbs-template') !== -1,
				}">
					<icon-mark width="18px" />{{
							isOpen ? translate("sidebar.templates") : ""
					}}
				</router-link>
				<div v-click-outside="(e) => closeSettingsMenu(e)" class="setting-menu"
					:class="{ 'is-open': isOpenSettingsMenu }">
					<div class="setting-menu-main-item" @click="openSettingsMenu">
						<icon-settings :fill="'#23252abe'" width="18px" />{{ isOpen ? translate("search_bar.settings") : "" }}
						<div class="arrow">
							<icon-sub-menu-arrow width="12px" />
						</div>
					</div>
					<ul>
						<li>
							<router-link :to="{ name: 'settings' }" :class="{
								opened: isOpen, 'router-link-active': $route.name == 'settings',
							}" @click="e=>closeSettingsMenu(e, true)">
								{{ translate("sidebar.general_settings") }}
							</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'user.access.welcome' }" class="main-menu__useraccess-item"
                                         v-if="false"
								:class="{
									opened: isOpen,
									'router-link-active':
										$route.name == 'user.access.welcome' ||
										$route.name == 'user.access',
								}" @click="e=>closeSettingsMenu(e, true)">
								{{
										translate("sidebar.user_access")
								}}
							</router-link>
						</li>
					</ul>
				</div>
				<router-link :to="{ name: 'help-center' }" class="main-menu__help-center-item" :class="{
					opened: isOpen,
					'router-link-active': $route?.name?.indexOf('help-center') !== -1,
				}">
					<icon-help-center width="18px"
						:fill="$route?.name?.indexOf('help-center') !== -1 ? '#2684fe' : '#23252abe'" />
					{{
							isOpen ? translate("sidebar.help_center") : ""
					}}
				</router-link>
				<router-link :to="{ name: 'other-apps' }" class="main-menu__help-center-item" :class="{
					opened: isOpen,
					'router-link-active': $route?.name?.indexOf('other-apps') !== -1,
				}">
					<icon-other-app width="18px" :fill="$route?.name?.indexOf('other-apps') !== -1 ? '#2684fe' : '#23252abe'" />
					{{
							isOpen ? translate("sidebar.our_apps") : ""
					}}
				</router-link>
				<router-link :to="{ name: 'release-notes' }" class="main-menu__releaser-notes-item" :class="{
					opened: isOpen,
					'router-link-active': $route?.name?.indexOf('release-notes') !== -1,
				}">
					<icon-release width="18px" :fill="$route?.name?.indexOf('release-notes') !== -1 ? '#2684fe' : '#23252abe'" />
					{{
							isOpen ? translate("sidebar.release_notes") : ""
					}}<span class="badge">new</span>
				</router-link>
				<a target="_blank" :class="{
					opened: isOpen,
				}" href="https://aneto.io/privacy-statement/">
					<icon-privacy width="18px" height="18px" :fill="'#23252abe'" />
					{{ isOpen ? translate("footer.privacy") : "" }}
				</a>
				<a target="_blank" :class="{
					opened: isOpen,

				}" href="https://aneto.io/terms/">
					<icon-terms width="18px" height="18px" :fill="'#23252abe'" />
					{{ isOpen ? translate("footer.terms_cond") : "" }}
				</a>
			</div>
			<div class="social-media" :class="{ 'hidden': !isOpen }">
				<app-social-media />
			</div>
			<div class="version" :class="{ 'compact': !isOpen }">
				{{ versionLabel }} {{ version }}
			</div>
		</div>
	</div>
</template>

<script>
import { ref, computed, onMounted, inject, watch } from "vue";
import { useStore } from "vuex";

import IconDashboard from "@/components/icons/IconDashboard";
import IconSettings from "@/components/icons/IconSettings";
import IconBag from "@/components/icons/IconBag";
import IconMark from "@/components/icons/IconMark";
import IconArrow from "@/components/icons/IconArrow";
import IconHelpCenter from "@/components/icons/IconHelpCenter";
import IconRelease from "@/components/icons/IconRelease";
import IconOtherApp from "@/components/icons/IconOtherApp"
import IconPrivacy from "@/components/icons/IconPrivacy";
import IconTerms from "@/components/icons/IconTerms";
import IconAppLogo from "@/components/icons/appLogo";
import IconSubMenuArrow from "@/components/icons/IconSubMenuArrow.vue"
import AppSearchPages from "@/components/shared/SearchPages.vue";
import AppTopMenu from "@/components/dashboard/TopMenu.vue";
import { useRouter } from "vue-router";
import { useCookies } from "@/helpers/cookies";
import AppSocialMedia from "@/components/shared/SocialMedia.vue";
export default {
	components: {
		IconDashboard,
		IconSettings,
		IconArrow,
		IconBag,
		IconMark,
		IconHelpCenter,
		IconRelease,
		IconOtherApp,
		IconPrivacy,
		IconTerms,
		IconAppLogo,
		AppSearchPages,
		AppTopMenu,
		IconSubMenuArrow,
		AppSocialMedia,
	},
	inject: ["translate"],
	props: {
		collapsed: {
			type: Boolean,
			default: false,
		}
	},
	setup() {
		const translate = inject("translate"),
			{ saveCookie } = useCookies(),
			{ readCookie } = useCookies(),
			store = useStore(),
			isOpen = ref(store.getters["app/isShowSideBar"]),
			isAdmin = computed(() => store.getters["profile/isAdmin"]),
			versionLabel = computed(() => isOpen.value ? 'Version' : 'v'),
			version = computed(() => window?.config?.version),
			router = useRouter(),
			isOpenSettingsMenu = ref(false),
			navBar = ref();

		const changeToggle = (value) => {
			if (isOpen.value) store.dispatch("app/showSideBar");
			else store.dispatch("app/hideSideBar");
			if (window.innerWidth < 1400) {
				if (isOpen.value == true) {
					document.body.style.overflow = "hidden";
					if (!window?.isTestRun)
						window?.scrollTo(0, 0);
				} else {
					document.body.style.overflow = "auto";
				}
			}
			setTimeout(() => {
				const { width } = navBar.value.getBoundingClientRect();
				store.dispatch("app/setNavBarWidth", width);
			}, 500);
			saveCookie('toogle-nav', isOpen.value)
		}
		const toggleNav = () => {
			isOpen.value = !isOpen.value;
			changeToggle(isOpen.value)
		};

		const openSettingsMenu = () => {
			isOpenSettingsMenu.value = !isOpenSettingsMenu.value;
		}

		const closeSettingsMenu = (e, directClose = false) => {
			if (isOpen.value) return;

			if (directClose) {
				isOpenSettingsMenu.value = false;
				return;
			}
			const path = e.path || (e.composedPath && e.composedPath());
			if (
				path.some((p) => {
					return (
						p.classList &&
						(p.classList.contains("setting-menu"))
					);
				})
			)
				return;

			isOpenSettingsMenu.value = false;
		}
		onMounted(async () => {
			const { width } = navBar.value.getBoundingClientRect();
			store.dispatch("app/setNavBarWidth", width);
			const value = await readCookie('toogle-nav');
			isOpen.value = true;
			if (value && value=='false')
				isOpen.value = false;

			changeToggle(isOpen.value)
		});

		return {
			isOpen,
			toggleNav,
			isAdmin,
			navBar,
			translate,
			version,
			versionLabel,
			isOpenSettingsMenu,
			openSettingsMenu,
			closeSettingsMenu,
		};
	},
};
</script>
<style lang="scss" scoped>
.sidebar {

	position: sticky;
	min-height: 740px;
	height: 100vh;
	background-color: #fff;
	padding-top: 10px;
	z-index: 10;
	top: 0;
	left: 0;

	@media (max-width: 1400px) {
		position: absolute;
		height: 100%;
		left: 0;
		z-index: 11;
	}

	.main-menu {
		display: grid;
		gap: 10px;
	}

	.toggle-btn {
		cursor: pointer;
		width: 25px;
		height: 42px;
		color: #fff;
		font-size: 0;
		position: absolute;
		top: 40%;
		right: -12px;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #FFFFFF;
		border: 1px solid #F0F0F0;
		border-radius: 28px;

		&:focus {
			outline: none;
		}

		.arrow {
			display: block;
			width: 6px;
			transition: all 0.3s linear;

			&.right {
				transform: rotate(180deg);
			}

			&.left {
				transform: rotate(0);
			}
		}
	}

	@media (max-width: 1400px) {
		min-height: 530px;
	}

	@media (max-width: 1400px) {
		position: absolute;
		height: 100%;
		left: 0;
		z-index: 11;
	}

	.navigation {
		display: grid;

		padding: 15px;
		white-space: nowrap;

		a {
			min-height: 38px;
			font-style: normal;

			font-size: 13px;
			line-height: 16px;
			color: #23252abe;
			text-decoration: none;
			padding-left: 15px;
			display: flex;
			align-items: center;
			text-overflow: ellipsis;

			svg {
				margin-right: 10px;
				vertical-align: middle;
				fill: #23252abe;
			}

			&.router-link-active {
				background: #E8F1FF;
				border-radius: 6px;

				font-size: 13px;
				line-height: 16px;
				color: #136AFD;
				opacity: 1;
				border-radius: 4px;

				svg {
					fill: #136AFD;
				}
			}
		}

		.setting-menu {
			position: relative;
			padding-left: 0;
			height: 38px;
			overflow: hidden;
			color: rgba(35, 37, 42, 0.74);

			.arrow {
				transform: rotate(180deg) translateX(10px);
			}

			&.is-open {
				height: auto;
				overflow: initial;

				.arrow {
					transform: rotate(0deg);
				}
			}

			.setting-menu-main-item {
				display: grid;
				grid-template-columns: 20px 1fr 20px;
				align-items: center;
				gap: 10px;
				cursor: pointer;
				color: #23252abe;
				padding-left: 15px;
				font-size: 13px;
				line-height: 16px;
				min-height: 38px;

				&.is-open {
					background: #F8F9FD;
					color: #23252abe;
					border-radius: 4px;
				}
			}

			ul {
				margin: 0;
				padding: 0;
				padding-left: 30px;
				list-style: none;
			}

			.router-link-active {
				background: #F8F9FD;
				color: #23252abe;
				border-radius: 4px;
				opacity: 1;
			}
		}

		&.collapsed {
			.arrow {
				display: none;
			}

			.router-link-active {
				width: 48px;
			}

			.setting-menu {
				ul {
					position: absolute;
					z-index: 2;
					background: #fff;
					left: 37px;
					top: 38px;
					padding: 8px;
					width: 188px;
					box-shadow: 0 1px 1px #00000026;
					border-radius: 4px;
					border: 1px solid #0000001a;
				}

				.router-link-active {
					width: 100%;
				}
			}
		}
	}

	.navigation {
		display: grid;
	}

	@media (max-width: 1400px) {
		min-height: 530px;
	}
}

.version {
	font-size: 12px;
	color: #a1aaba;
	padding-left: 21px;
	margin-top: 15px;

	&.compact {
		padding-left: 7px;
	}
}

.badge {
	align-items: center;
	background: #0085ff;
	border-radius: 4px;
	color: #fff;
	display: inline-flex;
	font-size: 11px;
	font-weight: 400;
	justify-content: center;
	margin-left: 15px;
	padding: 3px;
	height: 21px;
	display: none;
}

.opened {
	.badge {
		display: inline-flex;
	}
}

.search-create {
	display: flex;
	gap: 5px;
	border-bottom: 1px solid #F0F0F0;
	padding-bottom: 20px;
}

.logo-search {
	padding: 0 15px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.mini-logo {
	position: relative;
	overflow: hidden;

	&:after {
		content: '';
		display: block;
		height: 100%;
		width: 20px;
		background: #ffffff;
		position: absolute;
		right: -20px;
		top: 0;
	}
}
.social-media {
	display: none;
	&.hidden {
		display: none;
	}
}
</style>
