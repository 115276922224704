<template>
  <div class="grid" :class="{
    collapsed: isShowSideBar === false,
    'full-screen': isFullScreen,
    'is-get-started': !conversionScreenUsed,
  }">
      <div class="fade-background" @click="closeNav"></div>
      <app-side-nav v-if="conversionScreenUsed" ref="navBar" :collapsed="isShowSideBar === false" />
      <div id="main">
          <!-- New Release Block -->
          <new-release></new-release>
          <app-loader />
          <router-view v-slot="{ Component, route }">
              <component :is="Component" :key="route.path" />
          </router-view>
      </div>
      <app-server-error />
      <app-modal-root />
</div>
</template>
<script>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import router from "@/router";
import AppSideNav from "@/components/shared/SideNav";
import AppAccessMessage from "@/components/shared/AppAccessMessage";
import AppModalRoot from "@/components/modal/ModalRoot";
import AppLoader from "@/components/shared/Loader";
import AppServerError from "@/components/errors/ServerError";
import { useCookies } from "@/helpers/cookies";
import NewRelease from "./components/shared/NewRelease.vue";
export default {
  components: {
      NewRelease,
    AppSideNav,
    AppModalRoot,
    AppLoader,
    AppServerError,
      appAccessMessage: AppAccessMessage,
  },
  setup() {
    const store = useStore(),
      isShowSideBar = computed(() => store.getters["app/isShowSideBar"]),
        hasAccess = computed(() => store.getters["profile/hasAccess"]),
        showAccessMessage = computed(() => store.getters["profile/showAccessMessage"]),
        firstAdminUserDisplayName = computed(() => store.getters["profile/firstAdminUserDisplayName"]),
        tenantId = computed(() => store.getters["profile/tenantId"]),
        currentUserId = computed(() => store.getters["profile/currentUserId"]),
      conversionScreenUsed = computed(
        () => store.getters["profile/conversionScreenUsed"]
      ),
      navBar = ref(),
      isFullScreen = computed(() =>
        [
          "wbs-project",
          "wbs-project-list-view",
          "wbs-template-list-view",
          "wbs-template",
        ].includes(router.currentRoute.value.name)
      );

    //store.dispatch("profile/getInstance");
    const closeNav = () => {
      if (navBar.value) navBar.value.toggleNav();
    };

    const checkSharedUrl = () => {
      if (config.isLocal) return;
      const { saveCookie, readCookie } = useCookies();

      AP.getLocation(async (location) => {
        const url = new URL(location),
          id = url.searchParams.get("wbsProject") || url.searchParams.get("wbsTemplate");
        const viewStyle = 'displayAsList';//await readCookie(`view-style-${parseInt(atob(id))}`);
        saveCookie(`tree-style-${atob(id)}`, url.searchParams.get('treeStyle'));

        if (id && url.searchParams.get("wbsProject")) {
          if (viewStyle == 'displayAsList')
            router.push({ name: "wbs-project-list-view", params: { id: atob(id) } });
          else
            router.push({ name: "wbs-project", params: { id: atob(id) } });
        }
        if (id && url.searchParams.get("wbsTemplate")) {
          if (viewStyle == 'displayAsList')
            router.push({ name: "wbs-template-list-view", params: { id: atob(id) } });
          else
            router.push({ name: "wbs-template", params: { id: atob(id) } });
        }
      });
    };

    const checkNewReleaseLastRoute = async () => {
        if (config.isLocal) return;
        const { readCookie, saveCookie } = useCookies();
        const lastRoutePath = await readCookie("newReleaseLastRoutePath");

        if (lastRoutePath) {
            saveCookie('newReleaseLastRoutePath', '');
            router.push({ path: lastRoutePath });
        }
    };

    const redirectToGetStarted = () => {
      if (config.isLocal) return;
      AP.getLocation((location) => {
        const url = new URL(location),
          params = url.searchParams.get("wbs-special");
        if (params) {
          store.commit("profile/SET_CONVERSION_SCREEN_USED", false);
          router.push({ name: "dashboard.get-started" });
        }
      });
    };

    onMounted(() => {
        checkSharedUrl();
        checkNewReleaseLastRoute();
        redirectToGetStarted();

        Echo.private("addon." + tenantId.value + ".user." + currentUserId.value)
            .listen('AppReleaseCompleted', (response) => {
                store.commit("profile/SET_CURRENT_USER_NEW_RELEASE_AVAILABLE", response.newReleaseAvailable);
                store.commit("profile/SET_APP_VERSION", response.appVersion);
            });
    });

    return {
      navBar,
      closeNav,
      isShowSideBar,
        hasAccess,
        showAccessMessage,
      isFullScreen,
      conversionScreenUsed,
        firstAdminUserDisplayName
    };
  },
};
</script>
<style lang="scss">
// @import "assets/fonts/custom-fonts.css";
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500&display=swap');
@import "assets/scss/tooltip.scss";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&display=swap');

:root {
  --font-family: "Plus Jakarta Sans";
}

.text-center {
  text-align: center;
}

.cl-orange {
  color: #e47676;
}

.cl-blue {
  color: #0f8af9;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body,
html {
  background-color: #f2f5f9;
  --carousel-color-primary: rgba(54, 54, 54, 0.9);
  --carousel-nav-width: 46px;
}

#app {
  font-family: var(--font-family);
  color: #363636;
  position: relative;
  min-height: 100vh;
}

.grid {
  width: 100%;
  max-width: 1400px;
  min-width: 1024px;
  min-height: 100vh;
  position: relative;
  padding-top: 0;
  display: grid;
  grid-column-gap: 5px;
  transition: width 0.3s;
  grid-template-areas: "SideBar Content";
  grid-template-columns: 250px 1fr;

  &.full-screen {
    max-width: 100%;
    height: calc(100vh + 300px);
    padding-bottom: 0;

    .sidebar {
      position: fixed;
      left: 0;
      top: 0;
      height: 100vh;
    }
  }

  &.is-get-started {
    grid-template-columns: 1fr;
    grid-template-areas: "Content";
    margin: 15px;

    .fade-background {
      display: none;
    }
  }

  @media (max-width: 1400px) {
    padding: 0;
    grid-template-areas: "SideBar Content";
    grid-template-columns: 90px 1fr;
    grid-column-gap: 0;
  }

  .app-logo-name {
    grid-area: AppLogo;
    grid-template-columns: 140px 1fr;

    @media (max-width: 1400px) {
      grid-template-columns: auto 1fr;
      padding-top: 0;
      padding-bottom: 10px;

      svg {
        height: 35px;
      }
    }
  }

  .sidebar {
    width: 230px;
    grid-area: SideBar;
    transition: width 0.3s;
  }

  .fade-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: none;

    @media (max-width: 1400px) {
      display: block;
    }
  }

  &.collapsed {
    grid-template-columns: 100px 1fr;

    .fade-background {
      display: none;
    }

    .sidebar {
      width: 80px;
    }

    .app-logo-name {
      grid-template-columns: 140px 1fr;
    }
  }
}

#main {
  grid-area: Content;
  position: relative;
  padding: 10px 0;
}

input {
  &::placeholder {
    opacity: 1;
  }
}
</style>
