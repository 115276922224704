<template>
  <div class="dashboard">
    <div class="left-side">
      <app-widget>
        <template v-slot:icon>
          <icon-bag width="18px" :fill="'#ffffff'" />
        </template>
        <template v-slot:value> {{ projectsCount }} </template>
        <template v-slot:descr>{{ translate("dashboard.projects") }}</template>
      </app-widget>
      <app-widget>
        <template v-slot:icon>
          <icon-mark width="15px" :fill="'#ffffff'" />
        </template>
        <template v-slot:value> {{ templatesCount }} </template>
        <template v-slot:descr>{{ translate("dashboard.templates") }}</template>
      </app-widget>
      <app-widget>
        <template v-slot:icon>
          <icon-custom width="19px" :fill="'#ffffff'" />
        </template>
        <template v-slot:value> {{ activeProjects }} </template>
        <template v-slot:descr>{{
            translate("dashboard.active_projects")
        }}</template>
      </app-widget>
      <app-widget>
        <template v-slot:icon>
          <icon-users width="19px" :fill="'#ffffff'" />
        </template>
        <template v-slot:value> {{ activeParticipants }} </template>
        <template v-slot:descr>{{
            translate("dashboard.active_articipants")
        }}</template>
      </app-widget>

      <div class="widget-bottom">
        <app-widget-info>
          <template v-slot:title>{{
              translate("dashboard.hours_saved")
          }}</template>

          <template v-slot:title-icon>
            <icon-attention :width="'24px'" :height="'24px'"
              v-tooltip.top-center="translate('dashboard.estimated_tooltip')" />
          </template>
          <template v-slot:icon>
            <icon-time width="18px" :fill="'#ffffff'" />
          </template>
          <template v-slot:value> {{ hoursCount }} </template>
          <template v-slot:descr>{{
              translate("dashboard.hours_saved")
          }}</template>
        </app-widget-info>
      </div>
    </div>
    <div class="content">
      <div class="go-to-welcome" @click="openWelcomeScreen">
        <icon-attention :width="'21px'" :height="'21px'" />
      </div>
      <app-sessions-table :header="sessionsColumns">
        <template v-slot:icon>
          <icon-custom :width="'16px'" :height="'16px'" :fill="'#C3CAD9'" />
        </template>
        <template v-slot:row>
          <app-session-row :row="project" :key="project.id + 'data'" v-for="project in projects">
            <router-link @click.prevent="openUrl(project.id)" :to="{ name: 'wbs-project', params: { id: project.id } }">
              <icon-next-round :width="'30px'" />
            </router-link>
          </app-session-row>
          <div class="no-content" v-if="projects.length == 0 && !isLoading">
            {{ translate("dashboard.no_project") }}
          </div>
        </template>
      </app-sessions-table>
      <div class="buttons" v-if="projects.length">
        <app-button @click="openProjectScreen" color="default">{{
            translate("see_all")
        }}</app-button>
      </div>
      <app-templates-table :header="templatesColumns">
        <template v-slot:icon>
          <icon-mark :width="'14px'" :height="'14px'" :fill="'#C3CAD9'" />
        </template>
        <template v-slot:row>
          <app-template-row :row="template" :key="template.id + 'data'" v-for="template in templates">
            <router-link @click.prevent="openUrl(template.id, 'wbs-template')"
              :to="{ name: 'wbs-template', params: { id: template.id } }">
              <icon-next-round :width="'30px'" />
            </router-link>
          </app-template-row>
          <div class="no-content" v-if="templates.length == 0 && !isLoading">
            {{ translate("dashboard.no_templates") }}
          </div>
        </template>
      </app-templates-table>
      <div class="buttons" v-if="templates.length">
        <app-button @click="openTemplatesScreen" color="default">{{
            translate("see_all")
        }}</app-button>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, inject, onMounted, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import debounce from "lodash.debounce";
import AppButton from "@/components/shared/Button.vue";
import router from "@/router";
import IconBag from "@/components/icons/IconBag.vue";
import IconMark from "@/components/icons/IconMark.vue";
import IconUsers from "@/components/icons/IconUsers.vue";
import IconCustom from "@/components/icons/IconCustom.vue";
import IconAttention from "@/components/icons/IconAttention.vue";
import IconTime from "@/components/icons/IconTime.vue";
import IconNextRound from "@/components/icons/IconNextRound.vue";
import AppWidget from "@/components/shared/Widget.vue";
import AppWidgetInfo from "@/components/shared/WidgetInfo.vue";
import AppSessionsTable from "@/components/table/SessionsTable.vue";
import AppTemplatesTable from "@/components/table/TemplatesTable.vue";
import AppSessionRow from "@/components/table/rows/SessionRow.vue";
import AppTemplateRow from "@/components/table/rows/TemplateRow.vue";
import AppHeader from "@/components/shared/Header.vue";
import * as types from "@/store/mutation-types";
import { useKeyboard } from "@/helpers/keyboard";
import { useLocation } from "@/helpers/location";
import { useCookies } from "@/helpers/cookies";
import NewRelease from "../shared/NewRelease.vue";
export default {
  components: {
    AppButton,
    IconBag,
    IconMark,
    IconUsers,
    IconCustom,
    IconTime,
    IconAttention,
    IconNextRound,
    AppWidget,
    AppWidgetInfo,
    AppSessionsTable,
    AppTemplatesTable,
    AppSessionRow,
    AppTemplateRow,
    AppHeader,
      NewRelease
  },
  inject: ["translate"],
  setup() {
    const translate = inject("translate");
    const { readCookie } = useCookies();
    const store = useStore(),
      state = reactive({
        searchText: "",
        projectsCount: computed(() => store.state.dashboard.projectsCount),
        activeProjects: computed(
          () => store.getters["dashboard/activeProjects"]
        ),
        templatesCount: computed(() => store.state.dashboard.templatesCount),
        activeParticipants: computed(
          () => store.state.dashboard.activeParticipants
        ),
        activeSessions: computed(() => store.state.dashboard.activeSessions),
        totalSessions: computed(() => store.state.dashboard.totalSessions),
        totalTemplates: computed(() => store.state.dashboard.totalTemplates),
        hoursCount: computed(() => store.state.dashboard.hoursSaved),
        isLoading: computed(() => store.getters["loader/isLoading"]),
        sessionsColumns: computed(() => {
          return ["recently_used", "members", "nodes"]
            .map((c) => translate(`dashboard.${c}`))
            .concat(
              translate(`dashboard.total_projects`) +
              '<span style="color:#0f8af9"> ' +
              store.state.dashboard.projectsCount +
              "</span>"
            );
        }),
        sessions: computed(() => store.getters["dashboard/sessions"]),
        projects: computed(() => store.getters["dashboard/projects"]),
        templatesColumns: computed(() => {
          return ["most_popular_templates", "members", "nodes"]
            .map((c) => translate(`dashboard.${c}`))
            .concat(
              translate(`dashboard.total_templates`) +
              '<span style="color:#0f8af9">' +
              store.getters["dashboard/templatesCount"] +
              "</span>"
            );
        }),
        templates: computed(() => store.state.dashboard.templates),
      });

    const { isCtrlDown } = useKeyboard();
    const getDashboard = async () => {
      await store.dispatch("dashboard/getDashboard", {
        phrase: state.searchText,
        _page: 0,
        _limit: 3,
      });
    };

    const debSearch = debounce(async () => {
      getDashboard();
    }, 500);

    const search = () => {
      state.page = 1;
      debSearch();
    };

    const openWelcomeScreen = async () => {
      store.commit(`profile/${types.SET_SHOW_MAIN_INTRO}`, true);
      router.push({ name: "dashboard.welcome" });
    };

    const openProjectScreen = () => {
      router.push({ name: "projects" });
    };

    const openUrl = async (id, source = "wbs-project") => {
      const viewStyle = await readCookie(`view-style-${parseInt(id)}`);
      if (!viewStyle || viewStyle == 'displayAsList') {
        source = source + '-list-view';
      }
      if (isCtrlDown.value) {
        if (!config.isLocal) {
          const url = await useLocation().getUrl(id, ['wbs-project', 'wbs-project-list-view'].includes(source) ? 'templates' : 'projects');
          window.open(url, "_blank");
          return;
        } else {
          const url = `/${source + '-list-view'}/${id}`;
          window.open(url, "_blank");
        }
        return;
      }
      if (source.indexOf('wbs-project')!==-1)
        router.push({ name: 'wbs-project-list-view', params: { id } });
      else
        router.push({ name: 'wbs-template-list-view', params: { id } });
    };

    const openTemplatesScreen = () => {
      router.push({ name: "templates" });
    };

    onMounted(() => {
      getDashboard();
    });

    return {
      ...toRefs(state),
      isCtrlDown,
      search,
      openWelcomeScreen,
      openProjectScreen,
      openTemplatesScreen,
      openUrl,
    };
  },
};
</script>
<style lang="scss" scoped>
.dashboard {
  min-height: 690px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 350px 1fr;
  grid-template-areas:
    "Header Header"
    "LeftSide Content";

  .buttons {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 150px;

    .button {
      height: 40px;
      grid-area: 1 / 2;
    }
  }

  .header {
    grid-area: Header;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 350px;

    .header-title {
      grid-area: 1 / 1 / 1 / 1;
    }

    .search-control {
      grid-area: 1 / 2 / 1 / 2;
      display: grid;
      align-items: center;
      grid-auto-flow: column;
      grid-template-columns: 52px 1fr;
      grid-column-gap: 15px;
    }

    @media (max-width: 1400px) {
      .header-title {
        ::v-deep(.app-name) {
          font-size: 12px;
          line-height: 125%;
        }
      }
    }
  }

  @media (max-width: 1400px) {
    grid-template-columns: 250px 1fr;
    grid-template-rows: 0 auto;
    grid-template-areas:
      "LeftSide Header"
      "LeftSide Content";
  }

  .left-side {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 1400px) {
      grid-gap: 15px;
    }

    grid-area: LeftSide;
    grid-template-areas: "Widget1 Widget2"
    "Widget3 Widget4"
    "Widget5 Widget5";
  }

  .left-side .widget:nth-child(1) {
    grid-area: Widget1;
  }

  .left-side .widget:nth-child(2) {
    grid-area: Widget2;
  }

  .left-side .widget:nth-child(3) {
    grid-area: Widget3;
  }

  .left-side .widget:nth-child(4) {
    grid-area: Widget4;
  }

  .widget-bottom {
    grid-area: Widget5;
  }

  .content {
    grid-area: Content;
    background: #fff;
    padding: 20px 25px;
    border-radius: 8px;
    position: relative;

    @media (max-width: 1400px) {
      padding: 0;
      padding-right: 25px;
    }

    .go-to-welcome {
      position: absolute;
      right: 12px;
      top: 10px;
      cursor: pointer;
    }

    .no-content {
      font-size: 14px;
      text-align: center;
    }
  }
}
</style>
