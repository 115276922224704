import axios from 'axios';
import * as types from '@/store/mutation-types';

export default {
  getInstance({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/instance')
        .then((response) => {
          const instance = response;
          commit(types.SET_PROFILE_INSTANCE, instance);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setLanguage({ commit }, payload) {
    commit(types.SET_LANGUAGE, payload);
  },

  conversionScreenUsed({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put('/instance/conversion')
        .then((response) => {
          commit(types.SET_CONVERSION_SCREEN_USED, true);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

    acceptRelease({ commit }, { userId }) {
        return new Promise((resolve, reject) => {
            axios
                .put('/users/' + userId + '/accept-release')
                .then((response) => {
                    const instance = response;
                    commit(types.SET_PROFILE_INSTANCE, instance);

                    if (!instance.hasAccess) {
                        commit(types.SET_SHOW_ACCESS_MESSAGE, true);
                    } else {
                        commit(types.SET_SHOW_ACCESS_MESSAGE, false);
                    }

                    resolve(response);
                })
                .catch((error) => {
                    reject(promiseErrorHandler(error, 'users'));
                });
        });
    }
};
