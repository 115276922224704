import axios from 'axios';
import * as types from '@/store/mutation-types';
import errorToObject from "@/helpers/errorToObject";
export default {
  getImport({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/projects/` + projectId + `/import/history`, {
          hideLoader: true
        })
        .then((response) => {
          commit(types.SET_IMPORT_LIST, { data: response.data.data });
          resolve(response.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  launchImport({ commit }, { projectId }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/projects/` + projectId + `/import`, null, {
          hideLoader: true
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  launchSync({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/projects/` + projectId + `/sync`, {
          hideLoader: true
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  getFilterList({ commit }, params = { perPage: 10, search: '' }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/jira/filters?perPage=${params.perPage || 10}&search=${params.search || ''}`, {
          hideLoader: true
        })
        .then((response) => {
          commit(types.SET_FILTER_LIST, { data: response.data.data });
          resolve(response.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  getWbsState({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/${state.source}/${id}`, {
          hideLoader: true
        })
        .then((response) => {
          commit(types.SET_WBS_STATE, response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  setWbsState({ commit, state }, payload = {
    projectId,
    zoomLevel: 1,
    locationY: 0,
    displayOption: 'tree',
    orphanedChildrenCollapsed: false,
    displayTreeOption: 'horizontal'
  }) {
    commit(types.SET_WBS_STATE, payload);
    return new Promise((resolve, reject) => {
      axios
        .put(`/${state.source}/${payload.projectId}/states`, payload, {
          hideLoader: true
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  // jira
  getJiraProjects({ commit }, params = { perPage: 10, search: '' }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/jira/projects?perPage=${params.perPage || 10}&search=${params.search || ''}`)
        .then((response) => {

          commit(types.SET_JIRA_PROJECTS, response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  getJiraIssueStatuses({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/jira/issue-statuses`)
        .then((response) => {
          commit(types.SET_JIRA_STATUSES, response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  getJiraIssueStatusNode({ commit, state }, { projectId, nodeId }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/${state.source}/${projectId}/nodes/${nodeId}/issue-statuses`,
          {
            hideLoader: true
          })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  getJiraIssueStatusCategories({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/jira/issue-status-categories`)
        .then((response) => {
          commit(types.SET_JIRA_CATEGORIES, response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  getJiraPriorities({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/jira/priorities`, {
          hideLoader: true
        })
        .then((response) => {
          commit(types.SET_JIRA_PRIORITIES, response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  // nodes
  getNodes({ commit, state }, { projectId, hideLoader = false }) {
    const CancelToken = axios.CancelToken;
    window.source = CancelToken.source();

    return new Promise((resolve, reject) => {
      axios
        .get(`/${state.source}/${projectId}/nodes`, {
          hideLoader,
          cancelToken: window.source.token
        })
        .then((response) => {
          commit(types.SET_NODES, { data: response.data.data });
          commit(types.SET_NODES_META, response.data.meta);
          resolve(response.data);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
          } else {
            reject(errorToObject(error));
          }
        });
    });
  },
  createNewNode({ commit, state }, data = {}) {
    commit(types.DELETE_ERROR_NODE, data.id);
    return new Promise((resolve, reject) => {
      axios
        .post(`/${state.source}/${data.projectId}/nodes`, {
          ...data, ...{
            children: null,
            parent: null
          }
        }, {
          hideLoader: true
        })
        .then((response) => {
          resolve(response.data);
          if (response.data.data?.syncError) {
            commit(types.ADD_ERROR_NODE, { id: data.id, createdNode: response.data.data, action: 'create', error: response.data.data.syncErrorMessage });
          } else {
            commit(types.UPDATE_NODE, { id: data.id, createdNode: response.data.data });
          }
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
    createNewNodeWithJiraIds({ commit, state }, { data, hideLoader = true }) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/${state.source}/${data.projectId}/nodes/jira`, {
                    ...data
                }, {
                    hideLoader
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(errorToObject(error));
                });
        });
    },
  editNode({ commit, state }, { projectId, nodeId, node }) {
    let endpoint = state.source == 'projects' ? '/update' : '';
    return new Promise((resolve, reject) => {
      axios
        .put(`/${state.source}/${projectId}/nodes/${nodeId}${endpoint}`, {
          ...node, ...{
            codeOfAccounts: node.codeOfAccounts == 'NaN' || node.codeOfAccounts == '---' ? 0 : node.codeOfAccounts,
            children: null,
            parent: null
          }
        }, {
          hideLoader: true
        })
        .then((response) => {
          resolve(response.data);
          commit(types.UPDATE_LAST_UPDATE_MILLESECONDS, { nodeId, date: response.data.updatedAtMs })
          if (response.data.data?.syncError) {
            commit(types.ADD_ERROR_NODE, { id: nodeId, createdNode: response.data.data, action: 'edit', error: response.data.data.syncErrorMessage });
          }
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  updateCoa({ commit, state }, { projectId, nodeId, node }) {
    let endpoint = state.source == 'projects' ? '/update/coa' : '';
    return new Promise((resolve, reject) => {
      axios
        .put(`/${state.source}/${projectId}/nodes/${nodeId}${endpoint}`, {
          ...node, ...{
            codeOfAccounts: node.codeOfAccounts == 'NaN' || node.codeOfAccounts == '---' ? 0 : node.codeOfAccounts,
            children: null,
            parent: null
          }
        }, {
          hideLoader: true
        })
        .then((response) => {
          resolve(response.data);
          commit(types.UPDATE_LAST_UPDATE_MILLESECONDS, { nodeId, date: response.data.updatedAtMs })
          if (response.data.data?.syncError) {
            commit(types.ADD_ERROR_NODE, { id: nodeId, createdNode: response.data.data, action: 'edit', error: response.data.data.syncErrorMessage });
          }
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  editNodeWithJiraIds({ commit, state }, { projectId, nodeId, node }) {
    commit(types.DELETE_ERROR_NODE, nodeId);
    return new Promise((resolve, reject) => {
      axios
        .put(`/${state.source}/${projectId}/nodes/${nodeId}/jira`, {
          ...node, ...{
            children: null,
            parent: null
          }
        }, {
          hideLoader: true
        })
        .then((response) => {
          resolve(response.data);
          commit(types.CLEAR_CACHED_NODE_STATUS, nodeId);
          if (response.data.data?.syncError) {
            commit(types.ADD_ERROR_NODE, { id: nodeId, createdNode: response.data.data, action: 'edit', error: response.data.data.syncErrorMessage });
          }
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  deleteNode({ commit, state }, { projectId, nodeId, deleteFromJira = false, nextParent = null }) {
    commit(types.DELETE_ERROR_NODE, nodeId);
    return new Promise((resolve, reject) => {
      axios
        .delete(`/${state.source}/${projectId}/nodes/${nodeId}?deleteFromJira=${deleteFromJira}&nextParent=${nextParent}`, {
          hideLoader: true
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          const errorObject = errorToObject(error)
          reject(errorToObject(errorObject));
        });
    });
  },
    checkAndDeleteNode({ commit, state }, { projectId, jiraIssueKey }) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`/${state.source}/${projectId}/nodes/${jiraIssueKey}/check`, {
                    hideLoader: true
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(errorToObject(error));
                });
        });
    },
  latestNodesChanges({ commit }, { channelKey, modelId, lastUpdateDate }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/` + (channelKey === 'project' ? `projects` : `templates`) + `/${modelId}/latest-nodes-changes?lastUpdateDate=${lastUpdateDate}`, {
          hideLoader: true
        })
        .then((response) => {
          commit(types.SET_NODES_META, response.data.meta);
          resolve(response.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  // filters
  setFilters({ commit, state }, params = { id, projectId, issueTypeId, issueStatusId, assigneeId, statusCategoryId }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/${state.source}/${params.id}/filters`, params)
        .then((response) => {
          commit(types.SET_PROJECT_FILTERS, { data: response.data.data.filters });
          resolve(response.data.data.filters);
        })
        .catch((error) => {
          console.log('error=', error)
          reject(errorToObject(error));
        });
    });
  },
  addProjectParticipants({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/projects/${projectId}/participants`, null, {
          hideLoader: true,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    })
  },
  getProjectParticipants({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/projects/${projectId}/participants`, {
          hideLoader: true,
        })
        .then((response) => {
          commit(types.SET_PROJECT_PARTICIPANTS, response.data.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    })
  },
  deleteProjectParticipant({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/projects/${projectId}/participants`, {
          hideLoader: true,
        })
        .then((response) => {
          commit(types.SET_PROJECT_PARTICIPANTS, []);
          resolve(response.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        })
    })
  },
  setChildrenCollapsed({ commit, state }, { projectId, nodeId, viewOption, childrenCollapsed }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/${state.source}/${projectId}/nodes/${nodeId}/states`, { viewOption, childrenCollapsed }, {
          hideLoader: true
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  setChildrenCollapsedAll({ commit, state }, { projectId, viewOption, childrenCollapsed }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/${state.source}/${projectId}/nodes/states/all`, { viewOption, childrenCollapsed }, {
          hideLoader: true,
          skipException: true,
        })
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  addWorkLog({ commit, state }, { projectId, nodeId, timeSpent, remainingEstimate, dateStarted, comment }) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/${state.source}/${projectId}/nodes/${nodeId}/worklog`, {
          timeSpent, remainingEstimate, dateStarted, comment
        }, {
          hideLoader: true
        })
        .then((response) => {
          resolve(response.data);
          if (response.data.data?.syncError) {
            commit(types.ADD_ERROR_NODE, { id: nodeId, createdNode: response.data.data, action: 'edit', error: response.data.data.syncErrorMessage });
          }
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  getIssueTypesByProject({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/jira/issue-types?jiraProjectId=${projectId}`, {
          hideLoader: true,
        })
        .then((response) => {
          commit(types.SET_ISSUETYPES_BY_PROJECT, { projectId, data: response.data.data });
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    })
  },
  getProjectsReportLink({ commit }, { type, id }) {
    return new Promise((resolve, reject) => {
        axios
            .get(`/projects/${id}/${type}/report-link`)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(errorToObject(error));
            });
    });
},
    resetNodeWarnings({ commit, state }, { projectId, nodeId }) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`/${state.source}/${projectId}/nodes/${nodeId}/warnings`, {
                    hideLoader: true
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(errorToObject(error));
                });
        });
    },
};
