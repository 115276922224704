<template>
  <div class="modal-body">
    <div class="close" @click="closeModal">
      <icon-close :width="'10px'" :height="'10px'" :fill="'#0085FF'" />
    </div>
    <div class="title">
      {{ title }}
    </div>
    <div class="text">
      {{ text }}
    </div>
      <div class="text" v-if="showAdditionalText">
          {{ additionalText }}
      </div>
    <app-base-check-box v-if="showCheckBox" v-model="isChecked">{{
      checkBoxName
    }}</app-base-check-box>
    <div class="actions">
      <app-button color="default" @click="closeModal">
        {{ translate("modal.cancel") }}
      </app-button>
      <app-button color="red" @click="submit">{{ translate("modal.submit") }}</app-button>
    </div>
  </div>
</template>

<script>
import { useModalHelper } from "@/helpers/modalHelper";
import AppButton from "@/components/shared/Button";
import AppBaseCheckBox from "@/components/forms/elements/BaseCheckBox.vue";
import IconClose from "@/components/icons/IconClose.vue";
import { ref } from "vue";

export default {
  components: { AppButton, IconClose, AppBaseCheckBox },
  props: {
    title: {
      type: String,
      default: "Confirmation",
      required: true,
    },
    text: {
      type: String,
      default: {},
      required: true,
    },
    showCheckBox: {
      type: Boolean,
      default: false,
    },
    checkBoxName: {
      type: String,
      default: "",
    },
      showAdditionalText: {
          type: Boolean,
          default: false,
      },
      additionalText: {
        type: String,
          default: ""
      },
      nested: {
        type: Boolean,
          default: false
      }
  },
  inject: ["translate"],
  setup(props) {
    const { closeModal, submitModal, submitNestedModal } = useModalHelper(),
      isChecked = ref(false);

    const submit = () => {
      if (props.nested) {
        submitNestedModal({
            isChecked: isChecked.value,
        });
      } else {
          submitModal({
              isChecked: isChecked.value,
          });
      }
    };

    return {
      submit,
      closeModal,
      isChecked,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  position: relative;
  max-width: 420px;
  .close {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -21px;
    top: -14px;
    border: 1px solid #0085ff;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background: #0085ff;
      svg {
        fill: white;
      }
    }
  }
  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
  .text {
    font-weight: normal;
    font-size: 14px;
    line-height: 200%;
    text-align: center;
    color: #363636;
  }
  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    margin-top: 10px;
    .button {
      height: 55px;
      width: 100%;
    }
  }
}
</style>
