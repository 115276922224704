<template>
  <form @submit.prevent="submit" data-testid="hierarchy-tab-content">
    <div class="add-new-node" @click="addNewNode">
      <div class="name">
        <icon-add-node :width="'20px'" :height="'20px'" :stroke="'#A1A9BA'" />
        {{  translate("settings.add_new_node")  }}
      </div>
    </div>
    <div class="node-list">
      <div class="children-nodes custom-nodes" v-if="customNodes.length">
        <ul>
          <li :key="node.id" v-for="node in customNodes">
            <app-node-item-custom :relation="node.relation"
              @menuChange="(value) => menuChange({ target: node, value })">
              <template #icon>
                <template v-if="node.issueTypes.length">
                  <app-issue-panel :customList="node.issueTypes" :list="issueTypesList"
                    @save="(value) => saveIssueList({ target: node, value })">
                    <template :key="c.id" v-for="c in node.issueTypes">
                      <img v-if="c.isSelected === true" :src="c.iconUri" />
                    </template>
                  </app-issue-panel>
                </template>
                <img v-else :src="node.iconUri" />
              </template>
              <template #name> {{  node.name  }} </template>
              <template #actions>
                <icon-trash @click="deleteNode(node.id)" :width="'15px'" :height="'16px'" :fill="'transparent'"
                  :stroke="'#363636'" />
              </template>
            </app-node-item-custom>
          </li>
        </ul>
      </div>
      <div class="children-nodes default-nodes" v-if="defaultNodes.length">
        <ul>
          <li :key="node.id" v-for="node in defaultNodes">
            <app-node-item-default>
              <template #icon>
                <template v-if="node.issueTypes.length">
                  <app-issue-panel v-if="node.issueTypes.length" :customList="
                    node.issueTypes.filter(
                      (i) => !selectedCustomIssues.some((ci) => ci == i.name)
                    )
                  " :list="issueTypesList" @save="(value) => saveIssueList({ target: node, value })">
                    <template :key="c.id" v-for="(c, i) in node.issueTypes.filter(
                      (c) => c.isSelected == true
                    )">
                      <img v-if="i < maxShowCountIssue" :src="c.iconUri" />
                    </template>
                    <div class="more" v-if="
                      node.issueTypes.filter((c) => c.isSelected === true)
                        .length > maxShowCountIssue
                    ">
                      +{{
                       node.issueTypes.filter((c) => c.isSelected === true)
                       .length - maxShowCountIssue


                      }}
                    </div>
                  </app-issue-panel>
                </template>
                <img v-else :src="node.iconUri" />
              </template>
              <template #name> {{  node.name  }} </template>
            </app-node-item-default>
          </li>
        </ul>
      </div>
    </div>
    <br />
    <div class="error-list">
      <ul>
        <li :key="i" v-for="(error, i) in backendErrors">
          {{  error.text  }}
        </li>
      </ul>
    </div>
    <div class="actions" v-show="false">
      <app-button :color="'primary'" type="submit">{{
         translate("modal.save")
        }}</app-button>
    </div>
  </form>
</template>
<script>
import { reactive, ref, toRefs, computed, inject, onMounted } from "vue";
import { useStore } from "vuex";
import AppButton from "@/components/shared/Button";
import IconAddNode from "@/components/icons/IconAddNode.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import IconArrowDown from "@/components/icons/IconArrowDown.vue";
import AppNodeItemCustom from "@/components/settings/NodeItemCustom.vue";
import AppNodeItemDefault from "@/components/settings/NodeItemDefault.vue";
import { useModalHelper } from "@/helpers/modalHelper";
import AppIssuePanel from "@/components/settings/IssuePanel.vue";
import IconDotts from "@/components/icons/IconDottsMenu.vue";
import errorToList from "@/helpers/errorToList";
export default {
  inject: ["translate"],
  components: {
    AppButton,
    IconAddNode,
    IconTrash,
    IconArrowDown,
    AppNodeItemCustom,
    AppNodeItemDefault,
    AppIssuePanel,
    IconDotts,
  },
  setup() {
    const store = useStore(),
      state = reactive({
        hierarchy: computed(() => store.getters["settings/hierarchy"]),
        customNodes: computed(() => {
          return state.hierarchy.filter((c) => !c.default).map(node => {
            return {
              ...node, ...{
                issueTypes: []
              }
            }
          });
        }),
        backendErrors: [],
        defaultNodes: computed(() => {
          return state.hierarchy.filter((c) => c.default == true);
        }),
        existsIssues: computed(() => [
          ...new Set(
            state.hierarchy.reduce((acc, value) => {
              if (value.issueTypes.length && value.name === 'Standards') {
                acc.push(
                  ...value.issueTypes
                    .map((i) => {
                        return {
                            ...i,
                            selectedParentName: value.name,
                        }
                    })
                );
              }
              return acc;
            }, [])
          ),
        ]),
        issueTypesList: computed(() => {
          return [
            ...new Set([...new Map(store.getters["settings/issueTypes"].map((item) => [item["name"], item])).values()]),
          ];
        }),
        selectedCustomIssues: computed(() =>
          store.getters["settings/issueTypes"]
            .filter((i) => state.customNodes.some((n) => n.name == i.name))
            .map((i) => i.name)
        ),
        isFormValid: false,
        maxShowCountIssue: 3,
      }),
      { openModal, storeModal } = useModalHelper(),
      translate = inject("translate"),
      isOpenMenu = ref(null);

    const addNewNode = () => {
      openModal("SettingsNewNode", {
        title: computed(() => translate("settings.new_node_title")),
        existsList: state.existsIssues,
      });

        const unsubscribe = storeModal.subscribe(async (data) => {
            if (!data.type.startsWith("modal")) return;
            if (data.type === "modal/setData") {
                const newHierarchy = [...state.hierarchy];
                newHierarchy.unshift({
                    default: false,
                    isNew: true,
                    uniqieId: new Date().getTime(),
                    iconUri: data.payload.iconUri,
                    id: data.payload.id,
                    issueTypes: [],
                    jiraIssueLinkId: null,
                    jiraIssueTypeId: data.payload.jiraIssueTypeId,
                    name: data.payload.name,
                    parentId: null,
                });
                store.commit("settings/SET_HIERARCHY", { data: newHierarchy });
                submit();
            }
            unsubscribe();
        });
    };

    const deleteNode = (id) => {
      openModal("Confirmation", {
        title: computed(() => translate("modal.confirmation")),
        text: computed(() => translate("settings.confirm_delete")),
      });
      const unsubscribe = storeModal.subscribe(async (data) => {
        if (!data.type.startsWith("modal")) return;

        if (data.type === "modal/setData") {
          const node = state.hierarchy.find(
            (n) => n.id == id && n.default == false
          );
          if (node && node.isNew) {
            let newHierarchy = [...state.hierarchy];
            newHierarchy = newHierarchy.filter((c) => {
              return c.uniqieId !== node.uniqieId;
            });
            store.commit("settings/SET_HIERARCHY", { data: newHierarchy });
          } else {
            await store.dispatch("settings/deleteNode", id);
          }
        }
        unsubscribe();
      });
    };

    const menuChange = ({ target, value }) => {
      target.relation = value;
    };

    const submit = async () => {
      const newNodes = state.hierarchy
        .filter((n) => n.isNew)
        .map((item, i) => {
          const res = {};
          res[i] = item.id;
          return res;
        });

      const standarts = state.hierarchy
        .filter((n) => n.default == true)
        .map((n) => {
          return n.issueTypes.filter((i) => i.subtask == 0);
        })
        .find((i) => i.length > 0)
        .map((item) => {
          const res = {};
          res[item.id] = item.isSelected;
          return res;
        });

      let subtask = [];
      if (state.hierarchy.some((n) => n.default == true)) {
        try {
          subtask = state.hierarchy
            .filter((n) => n.default == true)
            .map((n) => {
              return n.issueTypes.filter((i) => i.subtask == 1);
            })
            .find((i) => i.length > 0)
            .map((item) => {
              const res = {};
              res[item.id] = item.isSelected;
              return res;
            });
        } catch {
          subtask = [];
        }
      }

      const result = {
        new: newNodes,
        standarts: standarts,
        subtask: subtask,
      };
      try {
        state.backendErrors = [];
        await store.dispatch("settings/saveNodesHierarchy", result);
      } catch (error) {
        state.backendErrors = errorToList(error);
      }
    };
    const openMenu = (id) => {
      if (isOpenMenu.value == id) {
        isOpenMenu.value = null;
        return;
      }

      isOpenMenu.value = id;
    };
    const closeMenu = () => {
      isOpenMenu.value = null;
    };
    const saveIssueList = ({ target, value }) => {
      target.issueTypes.forEach((i) => {
        const found = value.items.find((vi) => vi.id == i.id);
        if (found) {
          i.isSelected = found.isSelected;
        }
      });
      closeMenu();
      submit();
    };

    onMounted(() => {
      store.dispatch("settings/getNodesHierarchy");
      store.dispatch("settings/getIssueTypes");
    });

    return {
      ...toRefs(state),
      addNewNode,
      deleteNode,
      submit,
      menuChange,
      openMenu,
      closeMenu,
      isOpenMenu,
      saveIssueList,
    };
  },
};
</script>
<style lang="scss" scoped>
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.add-new-node {
  height: 60px;
  border: 1px dashed #c3cad9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .name {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #a1a9ba;
  }
}

.add-cutom-node {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #a1a9ba;
  grid-template-columns: 20px 1fr;
  justify-self: center;
  cursor: pointer;
}

.node-list {
  margin-top: 10px;
}

.children-nodes {
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 10px;

  &.default-nodes {
    background: #fbfbfc;
    border: 1px solid #00000028;
    margin-top: 10px;
    padding: 10px;
  }

  li+li {
    margin-top: 10px;
  }
}

.custom-node {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #a1a9ba;
  grid-template-columns: 20px 1fr;
  cursor: pointer;
  position: relative;
}

.icon {
  img {
    width: 16px;
    height: 16px;
  }
}

.error-list {
  ul {
    padding: 0;
    margin: 0;

    li {
      color: rgb(250, 89, 89);
    }
  }
}

.actions {
  margin-top: 20px;
  max-width: 200px;
}
</style>
