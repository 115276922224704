<template>
  <div class="row" ref="rowEl">
    <div class="cell">
      <div class="ava">
        <img v-if="row.iconUri" :src="row.iconUri" alt="" />
        <icon-default v-else :width="'35px'" :height="'35px'" />
        {{ row.name }}
      </div>
    </div>
    <div class="cell">
      <strong>{{ row.membersCount }}</strong>
    </div>
    <div class="cell">
      <strong>{{ row.nodesCount }}</strong>
    </div>
    <div class="cell open-template">
      <slot />
    </div>
  </div>
</template>
<script>
import { computed, reactive, toRefs } from "vue";
import IconDefault from "@/components/icons/IconDefault.vue";
import {useStore} from "vuex";
export default {
  components: { IconDefault },
  props: {
    row: {
      type: Object,
      default: {},
    },
  },

  emits: ["onRowChange", "onShowModal", "onCloseModal"],
  setup(props) {
    const state = reactive({
      row: props.row,
    }),
        store = useStore(),
        isAdmin = computed(() => store.getters["profile/isAdmin"]);

    return {
      ...toRefs(state),
        isAdmin
    };
  },
};
</script>

<style lang="scss" scoped>
.row {
  border-bottom: 1px solid #c3cad93d;
  display: grid;
  align-items: center;
  grid-column-gap: 0;
  grid-column-gap: 0;
  grid-template-columns: 1fr repeat(3, minmax(45px, 148px));

  &:hover {
    background: #f5f8fe;
  }
  @media (max-width: 1400px) {
    padding-left: 20px;
    padding-right: 20px;
    grid-template-columns: 1fr repeat(3, minmax(45px, 115px));
  }
}

.cell {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #363636;
  position: relative;
  strong {
    font-weight: 500;
  }
  @media (max-width: 1400px) {
    font-size: 12px;
    line-height: 18px;
  }
}

.ava {
  font-weight: 500;
  display: flex;
  align-items: center;

  svg {
    left: 7px;
    position: relative;
    margin-right: 15px;
    flex-shrink: 0;
  }
  img {
    flex-shrink: 0;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
    left: 7px;
    position: relative;
    @media (max-width: 1200px) {
      margin-right: 10px;
    }
  }
}

.open-template {
  justify-self: end;
  margin-right: 15px;
}
</style>
