<template>
  <div class="dashboard-menu" data-testid="dashboard-menu">
    <div class="dashboard-menu-icon" :class="{ 'active': isOpen }" @click="openClosePopup">
        <icon-plus-border :width="'12.5px'" :height="'12.5px'" :fill="isOpen ? '#fff' : '#136AFD'" />
    </div>
    <ul v-click-outside="(e) => closePopup(e)" class="dashboard-menu-list"
      :class="{ 'is-open': isOpen, 'left': position == 'left', right: position == 'right' }">
      <li>
        <router-link class="open-project-link" :to="{ name: 'projects', params: { openModal: true } }">
          {{ translate("projects.add_project") }}
        </router-link>
      </li>
      <li>
        <router-link class="open-template-link" :to="{ name: 'templates', params: { openModal: true } }">
          {{ translate("templates.add_template") }}
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import IconPlusBorder from "@/components/icons/IconPlusBorder.vue";
import {computed, onMounted, onUnmounted, reactive, toRefs} from "vue";
import {useStore} from "vuex";

export default {
  components: {
    IconPlusBorder,
  },
  inject: ["translate"],
  props: {
    position: {
      type: String,
      default: 'left',
      validator: (value) => {
        return ['left', 'right'].includes(value)
      }
    }
  },
  setup() {
    const store = useStore(),
        state = reactive({
            isOpen: false,
    }),
        isAdmin = computed(() => store.getters["profile/isAdmin"]);

    const openClosePopup = () => {
        state.isOpen = !state.isOpen;
    };
    const closePopup = (e) => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (
        path.some((p) => {
          return (
            p.classList &&
            (p.classList.contains("dashboard-menu") ||
              p.classList.contains("dashboard-menu-list"))
          );
        })
      )
        return;

      state.isOpen = false;
    };
    const onkeydown = (e) => {
      if (e.key == "Escape") state.isOpen = false;
    };
    onMounted(() => {
      document.addEventListener("keydown", onkeydown);
    });

    onUnmounted(() => {
      document.removeEventListener("keydown", onkeydown);
    });

    return {
      openClosePopup,
      closePopup,
      ...toRefs(state),
        isAdmin
    };
  },
};
</script>
<style lang="scss" scoped>
.dashboard-menu {
  position: relative;
}

.dashboard-menu-icon {
  width: 49px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #F2F2F2;

  &.active {
    background: #136AFD;
    border: 0;
  }

    &.disabled {
        opacity: .5;
        cursor: auto;
    }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  background: white;
  border: 5px solid rgba(39, 143, 247, 0.1);
  box-shadow: -4px 4px 15px rgb(0 0 0 / 5%);
  border-radius: 8px;
  position: absolute;
  transition: 0.2s;
  top: 100%;
  width: 200px;
  right: 0;
  opacity: 0;
  z-index: -1;
  visibility: hidden;

  &.left {
    right: 0;
  }

  &.right {
    left: calc(100% - 51px);
  }

  &.is-open {
    opacity: 1;
    visibility: visible;
    z-index: 2;
  }

  a {
    text-decoration: none;
    color: #444;
    display: block;
    padding: 10px 15px;

    &:hover {
      background: #2684fe;
      color: white;
    }
  }
}
</style>
